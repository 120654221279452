import React from "react";

const CurrentlyOffline = () => {
  return (
    <section className="currently-offline">
      <div className="title">Jsme offline</div>
      <div className="text">
        <p>
          Probíhají práce na webu, děláme všechno pro to, aby jste naše služby
          mohly využívat co nejdříve.
        </p>
        <p>
          Zatím nás můžete navštívit{" "}
          <a href="https://www.facebook.com/Jizersk%C3%BD-pension-722640561463376/">
            zde
          </a>
        </p>
      </div>
    </section>
  );
};

export default CurrentlyOffline;
