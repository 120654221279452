import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { cmsGalleryPage } from "../../store/actions/cmsGalleryActions";

import { connect } from "react-redux";

const CmsGallery = props => {
  const fakeData = [
    "ahoj",
    "xx",
    "yy",
    "lastOne",
    "ahoj",
    "xx",
    "yy",
    "lastOne",
    "ahoj",
    "xx",
    "yy",
    "lastOne",
    "ahoj",
    "xx",
    "yy",
    "lastOne"
  ];

  const itemsPerPage = 2;

  const pageCount = props.cmsGalleryPageReducer.images.length / itemsPerPage;

  const [data, changeData] = useState(fakeData);
  const [actualOffset, changeActualOffset] = useState(0);

  const fakeAjax = (offset, itemsOnPage) => {
    let realDatas = [];
    for (let x = offset; x < offset + itemsOnPage; x++) {
      realDatas.push(fakeData[x]);
    }
    const omg = renderContent(realDatas);
    return omg;
  };

  const renderContent = inData => {
    let commentNodes = inData.map((item, index) => {
      return <div key={index}> - {item.name} - </div>;
    });
    return commentNodes;
  };

  const handlePageClick = e => {
    props.cmsGalleryPage(itemsPerPage);
  };

  useEffect(() => {
    props.cmsGalleryPage(itemsPerPage);
  }, []);

  return (
    <div>
      <br />
      {renderContent(props.cmsGalleryPageReducer.images)}
      <br />
      <ReactPaginate
        previousLabel={"předchozí"}
        nextLabel={"další"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={3}
        pageRangeDisplayed={2}
        onPageChange={e => handlePageClick(e)}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    cmsGalleryPageReducer: state.cmsGalleryPageReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cmsGalleryPage: itemsPerPage => dispatch(cmsGalleryPage(itemsPerPage))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmsGallery);
