import React from "react";

const HomepageSection = props => {
  return (
    <section className="homepage-section">
      <h2>{props.title}</h2>
      {props.children}
    </section>
  );
};

export default HomepageSection;
