const initState = {
  images: [],
  loadingImages: true,
  lastId: null
};

const cmsGalleryPageReducer = (state = initState, action) => {
  switch (action.type) {
    case "INIT_GALLERY_ITEMS":
      return {
        ...state,
        loadingImages: true
      };
    case "SUC_GALLERY_ITEMS":
      let lastOneId = null;
      action.images.forEach((el, index) => {
        console.log("omg", el.uploadTime);
        if (action.images.length === index + 1) {
          console.log("not sure too");
          lastOneId = el.uploadTime;
        }
      });
      return {
        ...state,
        images: action.images,
        loadingImages: false,
        lastId: lastOneId
      };
    default:
      return state;
  }
};

export default cmsGalleryPageReducer;
