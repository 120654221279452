const initState = {
  moduleGlobalConfigIsLoading: true,
  moduleGlobalConfig: null,
  cmsInfoItemsIsLoading: true,
  cmsInfoItems: [],
  cmsInfoItemsNew: [],
  cmsInfoDeletedItems: [],
  isFatchingNewItem: false,
  savingGlobalConfig: false,
  savedGlobalConfig: false
};

const cmsInfoReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_INFO_MODULE_GLOBAL_INIT":
      return {
        ...state,
        moduleGlobalConfigIsLoading: true,
        moduleGlobalConfig: null,
        cmsInfoItems: []
      };
    case "GET_INFO_MODULE_GLOBAL_SUC":
      return {
        ...state,
        moduleGlobalConfigIsLoading: false,
        moduleGlobalConfig: action.globalConfig
      };
    case "UPDATE_INFO_GLOBAL_CONFIG":
      const updatedConfig = { ...state.moduleGlobalConfig };
      updatedConfig[action.id] = action.value;
      return {
        ...state,
        moduleGlobalConfig: updatedConfig
      };
    case "GET_INFO_MODULE_ITEMS_INIT":
      return {
        ...state,
        cmsInfoItemsNew: [],
        cmsInfoItemsIsLoading: true
      };
    case "GET_INFO_MODULE_ITEMS_SUC":
      return {
        ...state,
        cmsInfoItemsIsLoading: false,
        cmsInfoItems: action.items
      };
    case "INFO_PUSH_NEW_ITEM":
      const newCmsInfoItemsNew = [...state.cmsInfoItemsNew];
      newCmsInfoItemsNew.push(action.item);
      return {
        ...state,
        cmsInfoItemsNew: newCmsInfoItemsNew
      };
    case "INFO_UPDATE_NEW_ITEM":
      const newCmsInfoItemsNewUpdate = [...state.cmsInfoItemsNew];
      newCmsInfoItemsNewUpdate[action.position][action.id] = action.value;
      return {
        ...state,
        cmsInfoItemsNew: newCmsInfoItemsNewUpdate
      };
    case "INFO_UPDATE_EXISTING_ITEM":
      const newCmsInfoItemsForUpdate = [...state.cmsInfoItems];
      newCmsInfoItemsForUpdate.forEach(item => {
        if (item.id === action.idOfItem) {
          item[action.id] = action.value;
        }
      });
      return {
        ...state,
        cmsInfoItems: newCmsInfoItemsForUpdate
      };
    case "INFO_DELETE_NEW_ITEM":
      console.log("lets del position ", action.position);
      const newItemAfterDelete = [...state.cmsInfoItemsNew];
      console.log("old items", newItemAfterDelete);
      const itemsForPushForNew = [];
      newItemAfterDelete.forEach((item, index) => {
        if (index !== action.position) {
          itemsForPushForNew.push(item);
        }
      });
      console.log("this will be returned", itemsForPushForNew);
      return {
        ...state,
        cmsInfoItemsNew: itemsForPushForNew
      };
    case "INFO_CMS_UPDATE_DELETED_ITEMS":
      const updatedDeletedItems = [...state.cmsInfoDeletedItems, action.id];
      return {
        ...state,
        cmsInfoDeletedItems: updatedDeletedItems
      };
    case "INFO_CMS_UPDATE_SINGLE_ITEM_INIT":
      return {
        ...state,
        isFatchingNewItem: action.id
      };
    case "INFO_CMS_UPDATE_SINGLE_ITEM":
      // console.log("item", action.item, state);
      const returnUpdatedItems = state.cmsInfoItems.map(item => {
        if (item.id === action.item.id) {
          return action.item;
        } else {
          return item;
        }
      });
      return {
        ...state,
        isFatchingNewItem: false,
        cmsInfoItems: returnUpdatedItems
      };
    case "SAVE_INFO_GLOBAL_CONFIG_SUC":
      return {
        ...state,
        savingGlobalConfig: false,
        savedGlobalConfig: true
      };
    case "SAVE_INFO_GLOBAL_CONFIG_INIT":
      return {
        ...state,
        savingGlobalConfig: true
      };
    case "INFO_GLOBAL_CONFIG_CLEAR_SAVED":
      return {
        ...state,
        savedGlobalConfig: false
      };
    default:
      return state;
  }
};

export default cmsInfoReducer;
