import React from "react";
import ReactDOM from "react-dom";

const rootModal = document.getElementById("rootModal");

class Modal extends React.Component {
  el = document.createElement("div");

  componentDidMount() {
    rootModal.appendChild(this.el);
  }

  componentWillUnmount() {
    rootModal.removeChild(this.el);
  }

  render() {
    this.el.className = "modal-main";
    if (this.props.isVisible) {
      this.el.className = "modal-main active";
    }
    return ReactDOM.createPortal(
      <div className="modal-body">{this.props.children}</div>,
      this.el
    );
  }
}

export default Modal;
