import React from "react";
import AdminHomepageModuleWrapper from "./AdminHomepageModuleWrapper.js";
import AdminHomepageModuleInputWrapper from "./AdminHomepageModuleInputWrapper";
import AdminSpinner from "../../../AdminSpinner/AdminSpinner";

const AdminHomepageModuleNearby = props => {
  const { isVisible, name, order } = props.moduleData;
  const onChangeHandler = props.onChangeHandler;
  const onChangeHandlerForItems = props.onChangeHandlerForItems;
  const onSubmitHandler = props.onSubmitHandler;
  const items = props.items;

  const singleModuleItemsDataAdded = props.singleModuleItemsDataAdded;
  const onDeleteHandler = props.onDeleteHandler;
  const deleteModuleNewItem = props.deleteModuleNewItem;
  const onChangeHandlerForNewItems = props.onChangeHandlerForNewItems;
  const newItemConfig = {
    name: "",
    order: 0,
    distance: 0,
    isVisible: true,
    moreUrl: "",
    perex: ""
  };

  const trashIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
    >
      <path d="M 10 2 L 9 3 L 5 3 C 4.448 3 4 3.448 4 4 C 4 4.552 4.448 5 5 5 L 7 5 L 17 5 L 19 5 C 19.552 5 20 4.552 20 4 C 20 3.448 19.552 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.105 5.895 22 7 22 L 17 22 C 18.105 22 19 21.105 19 20 L 19 7 L 5 7 z" />
    </svg>
  );
  let newItemsRender = null;

  if (singleModuleItemsDataAdded.length > 0) {
    newItemsRender = singleModuleItemsDataAdded.map((item, index) => {
      return (
        <div className="sub-item new" key={"new-item_input" + index}>
          <div
            className="delete"
            onClick={itemPosition => deleteModuleNewItem(index)}
          >
            {trashIcon}
          </div>
          <AdminHomepageModuleInputWrapper label="Název">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="name"
              autocomplete="off"
              type="text"
              value={item.name}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Zobrazovat">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="isVisible"
              type="checkbox"
              checked={item.isVisible}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Pořadí">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="order"
              type="number"
              value={item.order}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Vzdálenost (Km)">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="distance"
              type="number"
              value={item.distance}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Text">
            <textarea
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="perex"
              value={item.perex}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Odkaz">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="moreUrl"
              type="text"
              value={item.moreUrl}
              autocomplete="off"
            />
          </AdminHomepageModuleInputWrapper>
        </div>
      );
    });
  }

  const itemsRender = items.map((item, index) => {
    return (
      <div className="sub-item" key={"item_input" + index}>
        <div className="delete" onClick={itemId => onDeleteHandler(item.id)}>
          {trashIcon}
        </div>
        <AdminHomepageModuleInputWrapper label="Název">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="name"
            type="text"
            autocomplete="off"
            value={item.name}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Zobrazovat">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="isVisible"
            type="checkbox"
            checked={item.isVisible}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Pořadí">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="order"
            type="number"
            value={item.order}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Vzdálenost (Km)">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="distance"
            type="number"
            value={item.distance}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Text">
          <textarea
            onChange={e => onChangeHandlerForItems(e, index)}
            id="perex"
            value={item.perex}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Odkaz">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="moreUrl"
            type="text"
            autocomplete="off"
            value={item.moreUrl}
          />
        </AdminHomepageModuleInputWrapper>
      </div>
    );
  });
  return (
    <AdminHomepageModuleWrapper label={name}>
      <form onSubmit={onSubmitHandler}>
        <AdminHomepageModuleInputWrapper label="Název">
          <input
            id="name"
            onChange={onChangeHandler}
            type="text"
            value={name}
            autocomplete="off"
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Zobrazovat">
          <input
            id="isVisible"
            onChange={onChangeHandler}
            type="checkbox"
            checked={isVisible}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Pořadí">
          <input
            id="order"
            onChange={onChangeHandler}
            type="number"
            value={order}
          />
        </AdminHomepageModuleInputWrapper>
        <div className="sub-heading">Položky</div>
        {itemsRender}
        {newItemsRender}
        <div className="new-items">
          <button
            type="button"
            className="add-new-row"
            onClick={() => props.addNewItemHandler(newItemConfig)}
          >
            Přidat další položku
          </button>
        </div>
        <button type="submit" className="btn btn-save">
          {props.isSubmiting ? <AdminSpinner text=" " /> : null}
          Uložit
        </button>
      </form>
    </AdminHomepageModuleWrapper>
  );
};

export default AdminHomepageModuleNearby;
