import React from "react";

import { Icon } from "antd";

export const returnMapOfIcons = () => {
  return [
    "camera",
    "question",
    "paw",
    "park",
    "phone-blender",
    "price-tag",
    "phone-outline",
    "phone",
    "envelope",
    "map",
    "star"
  ];
};

const IconRenderer = props => {
  const { iconName, theme } = props;
  switch (iconName) {
    case "map":
      return <i className="fas fa-map" />;
    case "envelope":
      return <i className="fas fa-envelope" />;
    case "phone":
      return <i className="fas fa-phone" />;
    case "phone-outline":
      return <Icon type="phone" />;
    case "price-tag":
      return <i className="fas fa-tags" />;
    case "phone-blender":
      return <i className="fas fa-blender-phone" />;
    case "park":
      return <i className="fas fa-parking" />;
    case "paw":
      return <i className="fas fa-paw" />;
    case "camera":
      return <Icon type="video-camera" />;
    case "question":
      return <Icon type="question" />;
    case "star":
      return <Icon type="star" theme={theme} />;
    default:
      return <Icon type="question" />;
  }
};

export default IconRenderer;
