import React from "react";

import HomepageModulesIndex from "../AdminHomePageModules/AdminHomePageModulesIndex";
import HomepageModulesDetail from "../AdminHomePageModules/AdminHomePageModulesDetail";

const HomepageModules = props => {
  const { id } = props;
  if (id === undefined) {
    return <HomepageModulesIndex />;
  } else {
    return <HomepageModulesDetail id={id} />;
  }
};

export default HomepageModules;
