export const fetchNotifications = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: "INIT_NOTIFICATIONS" });
    const firestore = getFirestore();
    let notifications = [];
    firestore
      .collection("notifications")
      .get()
      .then(res => {
        res.docs.forEach(notification => {
          const modifedNotification = { ...notification.data() };
          modifedNotification.id = notification.id;
          notifications.push(modifedNotification);
        });
        dispatch({ type: "INIT_NOTIFICATIONS_SUCCESS", notifications });
      })
      .catch(err => {
        dispatch({ type: "INIT_NOTIFICATIONS_FAILED", err });
      });
  };
};

export const fetchNotification = id => {
  return (dispatch, getState, { getFirestore }) => {};
};
