import React from "react";

import AdminNotificationsIndex from "./index";
import AdminNotificationsDetail from "./detail";

const AdminNotifications = props => {
  if (props.id) {
    return <AdminNotificationsDetail id={props.id} />;
  } else {
    return <AdminNotificationsIndex />;
  }
};

export default AdminNotifications;
