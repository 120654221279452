const initState = {
  settings: [],
  settingsLoaded: false
};

const settingsReducer = (state = initState, action) => {
  switch (action.type) {
    case "SETTINGS_LOADED":
      return {
        ...state,
        settingsLoaded: true,
        settings: action.settings
      };
    default:
      return state;
  }
};

export default settingsReducer;
