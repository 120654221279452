import React from "react";
import AdminHomepageModuleWrapper from "./AdminHomepageModuleWrapper.js";
import AdminHomepageModuleInputWrapper from "./AdminHomepageModuleInputWrapper";
import AdminSpinner from "../../../AdminSpinner/AdminSpinner";

const AdminHomepageModuleAboutUs = props => {
  const { content, isVisible, name, order } = props.moduleData;
  const onChangeHandler = props.onChangeHandler;
  const onSubmitHandler = props.onSubmitHandler;
  return (
    <AdminHomepageModuleWrapper label={name}>
      <form onSubmit={onSubmitHandler}>
        <AdminHomepageModuleInputWrapper label="Název">
          <input
            autocomplete="off"
            id="name"
            onChange={onChangeHandler}
            type="text"
            value={name}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper
          css={"checkbox-admin " + (isVisible ? "true" : null)}
          label="Zobrazovat"
          for="isVisible"
          text={isVisible ? "Ano" : "Ne"}
        >
          <input
            id="isVisible"
            onChange={onChangeHandler}
            type="checkbox"
            checked={isVisible}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Pořadí">
          <input
            id="order"
            onChange={onChangeHandler}
            type="number"
            value={order}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Text">
          <textarea id="content" onChange={onChangeHandler} value={content} />
        </AdminHomepageModuleInputWrapper>
        <button type="submit" className="btn btn-save">
          {props.isSubmiting ? <AdminSpinner text=" " /> : null}
          Uložit
        </button>
      </form>
    </AdminHomepageModuleWrapper>
  );
};

export default AdminHomepageModuleAboutUs;
