import React from "react";
import BackButton from "../../BackButton/BackButton";

const AdminHomepageModuleWrapper = props => {
  const backButtonRender = () => {
    let path = "/admin/homepage-modules";
    if (props.backButtonPath) {
      path = props.backButtonPath;
    }
    if (props.backButtonIsDisabled) {
      return null;
    } else {
      return <BackButton path={path} />;
    }
  };
  return (
    <div className="admin homepage-modules">
      <div className="heading">
        {backButtonRender()}
        {props.heading ? props.heading : "Modul hlavní stránky"}
        <span className="hp-module-sction-badge">{props.label}</span>
      </div>
      <div className="content__inner">
        <div className="hp-module-detail">
          <div className="hp-module-detail">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminHomepageModuleWrapper;
