import React from "react";
import HomepageSection from "../HomepageSection";

const HomepagePricing = props => {
  if (!props.modulePricingGlobal.isVisible) {
    return null;
  }

  const pricingRender = props.modulePricing.map((item, index) => {
    return (
      <div
        key={"pricing-" + index}
        className={!item.isMain ? "pricing-card" : "pricing-card main-offer"}
      >
        <div className="price">
          <div className="price-inner">
            <span>{item.price}</span>
            <span>CZK</span>
          </div>
        </div>
        <div className="title">{item.name}</div>
      </div>
    );
  });

  return (
    <HomepageSection title={props.modulePricingGlobal.name}>
      <div className="homepage-pricing">{pricingRender}</div>
    </HomepageSection>
  );
};

export default HomepagePricing;
