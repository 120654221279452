const initState = {
  homepageModules: [],
  homepageModulesOld: [],
  currentModule: null,
  homepageModulesLoading: false,
  currentModuleIsLoading: false,
  homepageModulesErrorMessage: null,
  saveLoaderForModules: false,
  singleModuleLoading: true,
  singleModuleData: null,
  singleModuleItemsData: [],
  singleModuleError: null,
  singleModuleIsSubmiting: false,
  singleModuleItemsDataAdded: []
};

const modulesReducer = (state = initState, action) => {
  switch (action.type) {
    case "INIT_MODULES":
      return {
        ...state,
        homepageModulesLoading: true,
        saveLoaderForModules: false,
        singleModuleItemsDataAdded: []
      };
    case "INIT_MODULES_FAIL":
      return {
        ...state,
        homepageModulesLoading: false,
        homepageModulesErrorMessage: action.err
      };
    case "INIT_MODULES_LOADED":
      return {
        ...state,
        homepageModulesLoading: false,
        homepageModulesErrorMessage: false,
        homepageModules: action.modules,
        homepageModulesOld: action.modules2
      };
    case "MODULES_DATA_CHANGE":
      const homepageModulesNew = state.homepageModules.map(module => module);
      homepageModulesNew.forEach(module => {
        if (module.id === action.moduleId) {
          module[action.id] = action.value;
        }
      });
      return {
        ...state,
        homepageModules: homepageModulesNew
      };
    case "IN_PROGRESS_SAVE_LOADER_MODULES":
      return {
        ...state,
        saveLoaderForModules: true
      };
    case "IN_PROGRESS_SAVE_LOADER_MODULES_FINISHED":
      return {
        ...state,
        saveLoaderForModules: false
      };
    case "SINGLE_MODULE_LOADING_START":
      const forceLoading = action.forceLoading;
      console.log("FL", forceLoading);
      //const forceLoading = true;
      return {
        ...state,
        singleModuleLoading: forceLoading,
        singleModuleError: null,
        singleModuleData: null,
        singleModuleItemsData: [],
        singleModuleItemsDataAdded: []
      };
    case "SINGLE_MODULE_LOADING_SUC":
      //console.log("DATTA", action.moduleItemsData);
      return {
        ...state,
        singleModuleError: null,
        singleModuleData: action.moduleData,
        singleModuleLoading: false,
        singleModuleItemsData: action.moduleItemsData
      };
    case "SINGLE_MODULE_LOADING_ERR":
      return {
        ...state,
        singleModuleLoading: false,
        singleModuleError: action.err,
        singleModuleData: null,
        singleModuleItemsData: []
      };
    case "SINGLE_MODULE_DATA_CHANGE":
      const { targetId, value } = action;
      // console.log("onChagne handler >> ", action.targetId, action.value);
      const newData = { ...state.singleModuleData };
      newData[targetId] = value;
      return {
        ...state,
        singleModuleData: newData
      };
    case "SINGLE_MODULE_DATA_CHANGE_FOR_ITEMS":
      const targetIdItem = action.targetId;
      const valueItem = action.value;
      const indexOfItem = action.index;

      let newDataForModuleItems = [...state.singleModuleItemsData];
      newDataForModuleItems.forEach((item, index) => {
        if (index === indexOfItem) {
          item[targetIdItem] = valueItem;
        }
      });
      return {
        ...state,
        singleModuleItemsData: newDataForModuleItems
      };
    case "SINGLE_MODULE_DATA_CHANGE_FOR_NEW_ITEMS":
      const targetIdItem2 = action.targetId;
      const valueItem2 = action.value;
      const indexOfItem2 = action.index;
      //ss
      let newDataForModuleItems2 = [...state.singleModuleItemsDataAdded];
      newDataForModuleItems2.forEach((item, index) => {
        if (index === indexOfItem2) {
          item[targetIdItem2] = valueItem2;
        }
      });
      return {
        ...state,
        singleModuleItemsDataAdded: newDataForModuleItems2
      };
    case "SINGLE_MODULE_SUBMIT_START":
      return {
        ...state,
        singleModuleIsSubmiting: true,
        singleModuleSubmitError: false
      };
    case "SINGLE_MODULE_SUBMIT_SUC":
      return {
        ...state,
        singleModuleIsSubmiting: false,
        singleModuleSubmitError: false
      };
    case "SINGLE_MODULE_SUBMIT_ERR":
      return {
        ...state,
        singleModuleIsSubmiting: false,
        singleModuleSubmitError: true
      };
    case "PUSH_NEW_ITEM_TO_MODULE":
      const newObjForItems = [...state.singleModuleItemsDataAdded];
      newObjForItems.push(action.newItem);
      //console.log(newObjForItems);
      return {
        ...state,
        singleModuleItemsDataAdded: newObjForItems
      };
    case "DELETE_NEW_ITEM_HAN":
      const newItemsToPushRemove = [];
      // const newNewItems = state.singleModuleItemsDataAdded.map(
      //   (item, index) => {
      //     if (index !== action.itemPosition) {
      //       newItemsToPushRemove.push(item);
      //     }
      //   }
      // );
      return {
        ...state,
        singleModuleItemsDataAdded: newItemsToPushRemove
      };
    default:
      return state;
  }
};

export default modulesReducer;
