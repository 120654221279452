const initState = {
  modulesLoading: true,
  modulesLoadingError: null,
  nearbyItemsData: [],
  pricesItemsData: [],
  contactsItemsData: [],
  homepageModulesData: [],
  galleryContent: []
};

const homepageReducer = (state = initState, action) => {
  switch (action.type) {
    case "MODULES_LOAD_START":
      return {
        ...state,
        modulesLoading: true,
        modulesLoadingError: null
      };
    case "MODULES_LOAD_SUC":
      return {
        ...state,
        nearbyItemsData: action.nearbyItemsData,
        pricesItemsData: action.pricesItemsData,
        contactsItemsData: action.contactsItemsData,
        homepageModulesData: action.homepageModulesData,
        galleryContent: action.galleryContent,
        modulesLoading: false,
        modulesLoadingError: null
      };
    case "MODULES_LOAD_FAIL":
      return {
        ...state,
        modulesLoading: false,
        modulesLoadingError: action.error
      };
    default:
      return state;
  }
};

export default homepageReducer;
