import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../../store/actions/authActions";

class AdminLogin extends Component {
  state = {
    email: "",
    password: ""
  };

  onChangeInputHandler = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmitFormHandler = e => {
    e.preventDefault();
    const cerds = { email: this.state.email, password: this.state.password };
    this.props.login(cerds);
  };

  render() {
    if (this.props.auth.uid) return <Redirect to="/admin/dashboard" />;
    return (
      <div className="admin-login">
        <div className="login-dialog">
          <div className="dialog-header">Přihlašte se</div>
          <div className="dialog-content">
            <form onSubmit={this.onSubmitFormHandler}>
              <div className="form-field-dialog">
                <input
                  onChange={this.onChangeInputHandler}
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={this.state.email}
                />
              </div>
              <div className="form-field-dialog">
                <input
                  onChange={this.onChangeInputHandler}
                  type="password"
                  id="password"
                  placeholder="Heslo"
                  value={this.state.password}
                />
              </div>
              <button type="submit">Přihlásit se</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: creds => dispatch(login(creds))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLogin);
