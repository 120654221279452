import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getVersionInfo } from "../../../store/actions/versionAction";
import { Link } from "react-router-dom";

const Dashboard = props => {
  const { versionReducer } = props;

  useEffect(() => {
    props.getVersionInfo();
  }, []);

  const getVersionItems = () => {
    const renderItems = versionReducer.versionContent.map((item, index) => {
      const { content, link, section, version } = item;
      return (
        <div
          className={
            versionReducer.version === version
              ? "version-info actual"
              : "version-info"
          }
          key={index}
        >
          <span className="version">
            <code>{version}</code>
          </span>
          <span className="section">
            <Link to={link || "#"}>{section}</Link>
          </span>
          <span className="content">{content}</span>
        </div>
      );
    });

    return <div className="version-info-box">{renderItems}</div>;
  };

  const successIcon = (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 52 52"
      style={{ width: "2rem" }}
    >
      <g>
        <path
          d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M40.495,17.329l-16,18
   C24.101,35.772,23.552,36,22.999,36c-0.439,0-0.88-0.144-1.249-0.438l-10-8c-0.862-0.689-1.002-1.948-0.312-2.811
   c0.689-0.863,1.949-1.003,2.811-0.313l8.517,6.813l14.739-16.581c0.732-0.826,1.998-0.9,2.823-0.166
   C41.154,15.239,41.229,16.503,40.495,17.329z"
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );

  const dangerIcon = (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 52 52"
      style={{ width: "2rem" }}
    >
      <path
        d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M28,41c0,1.104-0.896,2-2,2s-2-0.896-2-2v-2
 c0-1.104,0.896-2,2-2s2,0.896,2,2V41z M28,33c0,1.104-0.896,2-2,2s-2-0.896-2-2V11c0-1.104,0.896-2,2-2s2,0.896,2,2V33z"
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );

  if (props.linkNotFound) {
    console.log("not found", props.params);
  }
  console.log("omg?", versionReducer);
  return (
    <div className="admin dashboard">
      <div className="heading">Nástěnka</div>

      <div className="content__inner">
        <div className="dashboard-widgets admin-group in-progress-version">
          <h3>
            <span>Návštětvnost</span>
          </h3>
          <div className="widgets">
            <div className="widget">
              <span className="number">?</span>
              <span className="text">Celkový počet zobrazení stránky</span>
            </div>
            <div className="widget">
              <span className="number">?</span>
              <span className="text">Celkový počet rezervací</span>
            </div>
            <div className="widget">
              <span className="number">?</span>
              <span className="text">Nadcházející rezervace</span>
            </div>
          </div>
        </div>
      </div>

      <div className="content__inner">
        <div className="dashboard-widgets admin-group">
          <h3>
            <span>Status</span>
          </h3>
          {props.settings.settings[0].offlineMode && (
            <span className="guide guide-red">
              Stránka běží v offline režimu, administrátor ji vidět může,
              veřejnost však ne.
            </span>
          )}
          <label style={{ cursor: "pointer" }} htmlFor="offline">
            Offline režim:{" "}
          </label>{" "}
          <input
            style={{ cursor: "pointer" }}
            id="offline"
            type="checkbox"
            checked={props.settings.settings[0].offlineMode}
            onChange={() => props.offlineModeSwitcher()}
          />
        </div>
      </div>

      <div className="content__inner">
        <div className="dashboard-widgets admin-group">
          <h3>
            <span>
              {versionReducer.isFetching
                ? "Informace o verzi se připravují"
                : `Akutální verze : ${versionReducer.version}`}
            </span>
          </h3>
          <h5>Přehled hlavních akcích</h5>
          {!versionReducer.isFetching && getVersionItems()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    versionReducer: state.versionReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVersionInfo: () => dispatch(getVersionInfo())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
