export const fetchSettings = () => {
  return (dispatch, getState, { getFirestore }) => {
    //   dispatch({ type: "INIT_NOTIFICATIONS" });
    const firestore = getFirestore();
    //   let notifications = [];
    //   firestore
    //     .collection("notifications")
    //     .get()
    //     .then(res => {
    //       res.docs.forEach(notification => {
    //         const modifedNotification = { ...notification.data() };
    //         modifedNotification.id = notification.id;
    //         notifications.push(modifedNotification);
    //       });
    //       dispatch({ type: "INIT_NOTIFICATIONS_SUCCESS", notifications });
    //     })
    //     .catch(err => {
    //       dispatch({ type: "INIT_NOTIFICATIONS_FAILED", err });
    //     });
    let settings = [];
    firestore
      .collection("settings")
      .get()
      .then(res => {
        // console.log(res);
        res.docs.forEach(doc => {
          settings.push(doc.data());
        });
        dispatch({ type: "SETTINGS_LOADED", settings });
      });
  };
};
