export const getVersionInfo = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: "VERSION_INFO_START" });
    const firestore = getFirestore();

    firestore
      .collection("versionInfo")
      .orderBy("version", "desc")
      .get()
      .then(res => {
        let version = null;
        let versionContent = [];
        const files = res.docs.map(item => {
          if (item.id === "content") {
            version = item.data().versionNumber;
          } else {
            versionContent = [...versionContent, item.data()];
          }
        });
        dispatch({
          type: "VERSION_INFO_SUCCESS",
          version,
          versionContent
        });
      })
      .catch(e => {
        dispatch({
          type: "VERSION_INFO_FAIL"
        });
      });
  };
};
