import React from "react";
import HomepageSection from "../HomepageSection";

const HomepageAboutUs = props => {
  if (!props.moduleAboutUsGlobal.isVisible) {
    return null;
  }
  return (
    <HomepageSection title={props.moduleAboutUsGlobal.name}>
      <div>{props.moduleAboutUsGlobal.content}</div>
    </HomepageSection>
  );
};

export default HomepageAboutUs;
