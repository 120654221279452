export const login = creds => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "LOGIN_FAILED", err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

const fetchSettings2 = () => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //     });
    let settings = [];
    firestore
      .collection("settings")
      .get()
      .then(res => {
        // console.log(res);
        res.docs.forEach(doc => {
          settings.push(doc.data());
        });
        dispatch({ type: "SETTINGS_LOADED", settings });
      });
  };
};

export const offlineModeSwitcher = creds => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("settings")
      .doc("access")
      .update({ offlineMode: !getState().settings.settings[0].offlineMode })
      .then(() => {
        dispatch(fetchSettings2());
      });
  };
};
