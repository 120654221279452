import React, { useState } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Dashboard from "../AdminDashboardComponents/Dashboard";
import HomepageModules from "../AdminDashboardComponents/HomepageModules";
import AdminCmsPage from "../AdminCmsPages/AdminCmsPage";
import AdminNotifications from "../AdminNotifications/AdminNotifications";

import {
  signOut,
  offlineModeSwitcher
} from "../../../store/actions/authActions";

const AdminDashboard = props => {
  const [cmsPagesSubmenuState, cmsPagesSubmenuStateToggler] = useState(
    props.match.params.component === "cms" ? true : false
  );

  // console.log("props", props.match.params.component);

  const cmsPagesSubmenuStateToggleHandler = () => {
    cmsPagesSubmenuStateToggler(!cmsPagesSubmenuState);
    console.log(cmsPagesSubmenuState);
  };

  const webViewIcon = (
    <svg
      id="Layer_1"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      style={{ fill: "#fff", width: "2rem" }}
    >
      <path d="m231.139 150h-46.951l.174 137h46.602z" />
      <path d="m36 327h440v63h-440z" />
      <path d="m230.928 315 .021-16h-46.572l.021 16z" />
      <path d="m36 315h136.4l-.218-170.788a6.689 6.689 0 0 1 .631-2.845l29.481-59.167a5.993 5.993 0 0 1 10.738-.04l29.484 59.1a7.159 7.159 0 0 1 .63 2.95l-.218 170.79h13.072v-225.538a5.157 5.157 0 0 1 5.506-5.462h72.314c3.314 0 6.18 2.148 6.18 5.462v225.538h136v-274h-440z" />
      <path d="m275.992 281h-7.992v34h60v-219h-60v14h18.365a6 6 0 0 1 0 12h-18.365v20h7.992a6 6 0 0 1 0 12h-7.992v19h18.4a6 6 0 0 1 0 12h-18.4v20h7.953a6 6 0 0 1 0 12h-7.953v20h18.4a6 6 0 0 1 0 12h-18.4v20h7.992a6 6 0 0 1 0 12z" />
      <path d="m369.533 460h-41.656l-16.426-58h-110.9l-16.426 58h-41.658a6 6 0 1 0 0 12h227.066a6 6 0 0 0 0-12z" />
    </svg>
  );

  // const [active, cmsSubmenuToggler] = useState(initialCount);

  // const user = firebase.auth().getUser("S");

  // firebase
  //   .auth()
  //   .getUser(uid)
  //   .then(function(userRecord) {
  //     // See the UserRecord reference doc for the contents of userRecord.
  //     console.log("---Successfully fetched user data:", userRecord.toJSON());
  //   })
  //   .catch(function(error) {
  //     console.log("---Error fetching user data:---", error);
  //   });

  //leva zadni

  // const active = false;

  const toggleCmsMenu = () => {
    // active = !active;
  };

  const { email, uid } = props.auth;
  if (!props.auth.uid) return <Redirect to="/admin" />;
  const links = {
    dashboard: { url: "dashboard", component: <Dashboard /> },
    homepageModules: {
      url: "homepage-modules",
      component: <HomepageModules />
    },
    cmsPages: { url: "cms", component: <AdminCmsPage /> },
    notifications: {
      url: "notifications",
      component: <AdminNotifications />
    }
  };
  let componentToRender = null;
  switch (props.match.params.component) {
    case links.dashboard.url:
      componentToRender = (
        <Dashboard
          settings={props.settings}
          offlineModeSwitcher={() => props.offlineModeSwitcher()}
        />
      );
      break;
    case links.homepageModules.url:
      componentToRender = <HomepageModules id={props.match.params.id} />;
      break;
    case links.cmsPages.url:
      componentToRender = <AdminCmsPage id={props.match.params.id} />;
      break;
    case links.notifications.url:
      componentToRender = (
        <AdminNotifications
          id={props.match.params.id}
          offlineModeSwitcher={() => props.offlineModeSwitcher()}
        />
      );
      break;
    default:
      componentToRender = (
        <Dashboard
          linkNotFound
          params={props.match}
          settings={props.settings}
        />
      );
  }
  return (
    <div className="admin admin-dashboard">
      <div className="side-menu">
        <div className="user-section">
          <NavLink to={"/admin/user/" + uid}>
            <span>Martin Pražák</span>
            <span>{email}</span>
          </NavLink>
          <Link to="/admin" onClick={props.signOut}>
            Odhlásit se
          </Link>
        </div>
        <nav className="aside-menu__nav">
          <NavLink to="/admin/dashboard">Dashboard</NavLink>
          <NavLink to="/admin/homepage-modules">Moduly hlavní stránky</NavLink>
          <div
            className={
              cmsPagesSubmenuState ? "with-sub-nav active" : "with-sub-nav"
            }
          >
            <a onClick={() => cmsPagesSubmenuStateToggleHandler()}>Stránky</a>
            <div
              className="sub-nav"
              style={
                cmsPagesSubmenuState
                  ? { height: 56 * 2 + "px" } /* 56 * items*/
                  : { height: "0px" }
              }
            >
              <NavLink to="/admin/cms/gallery">Galerie</NavLink>
              <NavLink to="/admin/cms/info">Informace o ubytování</NavLink>
            </div>
          </div>
          <NavLink to="/admin/notifications">Oznamení</NavLink>
          <NavLink to="/admin/users">Uživatelé</NavLink>
          <NavLink to="/admin/reservations">Rezervace</NavLink>
        </nav>
        <NavLink className="button-bottom" to="/">
          {webViewIcon} Zobrazit web
        </NavLink>
      </div>
      <div className="dashboard-content">{componentToRender}</div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authInfo: state.auth,
    settings: state.settings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    offlineModeSwitcher: () => dispatch(offlineModeSwitcher())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboard);
