import React from "react";
import { Link } from "react-router-dom";

const BackButton = props => {
  return (
    <Link to={props.path} className="admin back-button">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="459px"
        height="459px"
        viewBox="0 0 459 459"
      >
        <g>
          <g id="reply">
            <path d="M178.5,140.25v-102L0,216.75l178.5,178.5V290.7c127.5,0,216.75,40.8,280.5,130.05C433.5,293.25,357,165.75,178.5,140.25z" />
          </g>
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    </Link>
  );
};

export default BackButton;
