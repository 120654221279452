import React, { useState, useEffect } from "react";
import ScreenMenu from "../Menu/ScreenMenu";
import IconRenderer from "../IconRenderer/IconRenderer";
import { getFirestore } from "redux-firestore";
import { Icon } from "antd";

const renderItems = (items, loading) => {
  const render = items.map((item, index) => {
    return (
      <div key={index} className="about-card">
        <span className="a-b__icon">
          <IconRenderer iconName={item.icon} />
        </span>
        <div className="a-b__title">{item.name}</div>
        <div className="a-b__description">{item.description}</div>
      </div>
    );
  });
  return render;
};

const About = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [globalInfo, setGlobalInfo] = useState({});

  const getAboutItems = () => {
    const firestore = getFirestore();

    let promises = [];

    promises.push(firestore.collection("cmsContentInfo").get());
    promises.push(
      firestore
        .collection("cmsPages")
        .doc("info")
        .get()
    );

    Promise.all(promises).then(res => {
      const itemsClear = res[0].docs.map(doc => {
        return doc.data();
      });
      const aboutInfoGlobal = res[1].data();
      setGlobalInfo(aboutInfoGlobal);
      setItems(itemsClear);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAboutItems();
  }, []);
  if (loading) {
    return (
      <div className="about-page single-page">
        <ScreenMenu miniActive />
        <div className="container">
          <div className="loading-content">
            <Icon type="loading" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="about-page single-page">
      <ScreenMenu miniActive />
      <div className="container">
        <h1>{globalInfo.name}</h1>
        <h6>{globalInfo.perex}</h6>
        <div className="about-cards">{renderItems(items, loading)}</div>
      </div>
    </div>
  );
};

export default About;
