export const fetchHomePageModules = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "MODULES_LOAD_START" });

    const firestore = getFirestore();
    const promises = [];

    promises.push(firestore.collection("homepageModulesNearbyItems").get());
    promises.push(firestore.collection("homepageModulesPricesItems").get());
    promises.push(firestore.collection("homepageModulesContactsItems").get());
    promises.push(firestore.collection("homepageModules").get());
    // promises.push(firestore.collection("cmsContentGallery").get());
    promises.push(
      firestore
        .collection("cmsContentGallery")
        .where("showOnHomepage", "==", true)
        .get()
    );

    Promise.all(promises)
      .then(res => {
        const nearbyItemsRaw = res[0];
        const pricesItemsRaw = res[1];
        const contactsItemsRaw = res[2];
        const homepageModulesRaw = res[3].docs;
        const galleryContentRaw = res[4].docs;

        let nearbyItemsData = [];
        let pricesItemsData = [];
        let contactsItemsData = [];
        let homepageModulesData = [];
        let galleryContent = [];

        nearbyItemsRaw.forEach(item => {
          nearbyItemsData.push(item.data());
        });

        pricesItemsRaw.forEach(item => {
          pricesItemsData.push(item.data());
        });

        pricesItemsData.sort((a, b) => {
          return a.order >= b.order ? 1 : -1;
        });

        contactsItemsRaw.forEach(item => {
          contactsItemsData.push(item.data());
        });

        homepageModulesRaw.forEach(item => {
          homepageModulesData.push(item.data());
        });

        galleryContentRaw.forEach(item => {
          galleryContent.push(item.data());
        });

        galleryContent.sort((a, b) => {
          return a.order >= b.order ? 1 : -1;
        });

        dispatch({
          type: "MODULES_LOAD_SUC",
          nearbyItemsData,
          pricesItemsData,
          contactsItemsData,
          homepageModulesData,
          galleryContent
        });
      })
      .catch(err => {
        dispatch({ type: "MODULES_LOAD_FAIL", err });
      });
  };
};
