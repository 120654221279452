import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Homepage from './components/Homepage/Homepage'
import AdminLogin from './components/Admin/AdminLogin/AdminLogin'
import AdminDashboard from './components/Admin/AdminDashboard/AdminDashboard'
import AdminHomePageModules from './components/Admin/AdminHomePageModules/AdminHomePageModules'
import CurrentlyOffline from './components/CurrentlyOffline/CurrentlyOffline'
import AdminSpinner from './components/AdminSpinner/AdminSpinner'
import About from './components/About/About'
import Reservation from './components/Reservation/Reservation'

import moment from 'moment'
import 'moment/locale/cs'

import Cookies from 'js-cookie'

//tst
import Try from './components/Admin/AdminHomePageModules/AdminHomepageModule/Try'
//tst

//cmsPages
import CmsGallery from './components/CmsPages/CmsGallery'

import { fetchSettings } from './store/actions/settingsActions'

import './content/sass/main.scss'

moment.locale('cs')

class App extends Component {
  cookiesBarRef = React.createRef()

  componentDidMount() {
    this.props.fetchSettings()
  }

  setCookieForAgreement = () => {
    Cookies.set('jizersky-pension-cookies-agreement', true, { expires: 10000 })
    this.cookiesBarRef.current.classList.add('disabled-bar')
  }

  render() {
    const cookiesBar = (
      <div className="cookie-bar" ref={this.cookiesBarRef}>
        <p>
          Naše stránka používá soubory cookies, používáním této stránky tím
          souhlasíte.
        </p>
        <div className="buttons">
          <button onClick={() => this.setCookieForAgreement()}>Rozumím</button>
          <a
            target="_blank"
            href="https://policies.google.com/technologies/cookies?hl=cs">
            Více informací
          </a>
        </div>
      </div>
    )
    if (this.props.settings.settingsLoaded) {
      let renderOfflineRoute = null
      if (this.props.settings.settings[0].offlineMode) {
        if (this.props.auth.isEmpty) {
          renderOfflineRoute = <Route path="/" component={CurrentlyOffline} />
        }
      }
      return (
        <BrowserRouter>
          {!Cookies.get('jizersky-pension-cookies-agreement') === true &&
            cookiesBar}
          <div className="app">
            {/*navbar*/}
            <Switch>
              <Route exact path="/admin" component={AdminLogin} />
              <Route
                exact
                path="/admin/:component"
                component={AdminDashboard}
              />
              <Route
                exact
                path="/admin/:component"
                component={AdminDashboard}
              />
              <Route
                exact
                path="/admin/:component/:id"
                component={AdminDashboard}
              />
              {renderOfflineRoute}
              <Route
                exact
                path="/"
                component={() => (
                  <Homepage
                    adminView={
                      !this.props.auth.isEmpty &&
                      this.props.settings.settings[0].offlineMode
                        ? true
                        : false
                    }
                  />
                )}
              />
              <Route exact path="/informace-o-ubytovani" component={About} />
              <Route exact path="/admin" component={AdminLogin} />
              <Route exact path="/galerie" component={CmsGallery} />
              <Route exact path="/try" component={Try} />
            </Switch>
          </div>
        </BrowserRouter>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            left: '0',
            top: '0',
          }}>
          <AdminSpinner />
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    settings: state.settings,
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSettings: () => dispatch(fetchSettings()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

// test
