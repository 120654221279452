import React from "react";

const AdminHomepageModuleInputWrapper = props => {
  return (
    <div className={props.css ? props.css + " module-row" : "module-row"}>
      <label htmlFor={props.for ? props.for : null}>{props.label}</label>
      {props.text ? <span>{props.text}</span> : null}
      {props.children}
    </div>
  );
};

export default AdminHomepageModuleInputWrapper;
