import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AdminSpinner from "../../AdminSpinner/AdminSpinner";
import { Input, Checkbox } from "antd";

import {
  fetchModules,
  moduleChangerHandler,
  onSubmitHandler
} from "../../../store/actions/modulesActions";

class HomepageModulesIndex extends Component {
  componentDidMount() {
    this.props.fetchModules();
  }

  // onChangeHandler = (moduleId, e) => {
  //   //console.log(moduleId, e.target.value);

  //   let valueToHandle;

  //   if (e.target.type === "checkbox") {
  //     valueToHandle = e.target.checked;
  //   } else if (e.target.type === "number") {
  //     valueToHandle = Number(e.target.value);
  //   } else {
  //     valueToHandle = e.target.value;
  //   }

  //   this.props.moduleChangerHandler(moduleId, e.target.id, valueToHandle);
  // };

  onChangeHandler = (moduleId, e) => {
    console.log("e", e.target);
    let id, value;
    if (e.target.type === "text") {
      id = e.target.id;
      value = e.target.value;
    } else if (e.target.type === "checkbox") {
      id = e.target.id;
      value = e.target.checked;
    }
    console.log("omfg", { id, value });
    this.props.moduleChangerHandler(moduleId, id, value);
  };

  onSubmitHandler = e => {
    e.preventDefault();
    this.props.onSubmitHandler();
  };

  // onChangeA = (id, value) => {
  //   console.log(value);
  //   let valueToHandle;
  //   if (value.target) {
  //     valueToHandle = value.target.checked;
  //   } else {
  //     valueToHandle = value;
  //   }
  //   console.log("Changing -> value : " + valueToHandle + "id: " + id);
  // };

  render() {
    //console.log(this.props);
    const { modules, modulesLoading, saveLoaderForModules } = this.props;

    //console.log("NVM homepageModulesOld", homepageModulesOld);

    let renderModules;

    if (modulesLoading) {
      renderModules = <AdminSpinner />;
    } else {
      renderModules = modules.map(module => {
        return (
          <tr key={module.id}>
            <td>{module.name}</td>
            <td>
              {/*<input
                id="isVisible"
                onChange={e => this.onChangeHandler(module.id, e)}
                type="checkbox"
                defaultChecked={module.isVisible}
              />*/}
              <Checkbox
                id="isVisible"
                onChange={e => this.onChangeHandler(module.id, e)}
                checked={module.isVisible}
              />
            </td>
            <td>
              <Input
                id="order"
                type="number"
                onChange={e => this.onChangeHandler(module.id, e)}
                value={module.order}
              />
              {/*<input
                id="order"
                onChange={e => this.onChangeHandler(module.id, e)}
                type="number"
                value={module.order}
              />*/}
            </td>
            <td>
              <Link to={"/admin/homepage-modules/" + module.id}>Detail</Link>
            </td>
          </tr>
        );
      });
    }

    return (
      <div className="admin homepage-modules">
        <div className="heading">Moduly hlavní stránky</div>
        <div className="content__inner">
          <div className="admin-table">
            <form onSubmit={this.onSubmitHandler}>
              {modulesLoading ? <AdminSpinner /> : null}
              <table>
                {/*<AdminSpinner full />*/}
                <thead>
                  <tr>
                    <th>Název</th>
                    <th>Zobrazovat</th>
                    <th>Pořadí</th>
                  </tr>
                </thead>
                <tbody>
                  {modulesLoading ? null : renderModules}
                  <tr>
                    <td
                      style={{ textAlign: "right", paddingTop: "1.5rem" }}
                      colSpan="4"
                    >
                      <button type="submit" className="btn btn-save">
                        {saveLoaderForModules ? (
                          <AdminSpinner text=" " />
                        ) : null}
                        Uložit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
          {/*          <AdminMessage color="green" text="Uloženo" />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    modules: state.modules.homepageModules,
    modulesLoading: state.modules.homepageModulesLoading,
    homepageModulesOld: state.modules.homepageModulesOld,
    saveLoaderForModules: state.modules.saveLoaderForModules
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchModules: () => dispatch(fetchModules()),
    moduleChangerHandler: (moduleId, id, value) =>
      dispatch(moduleChangerHandler(moduleId, id, value)),
    onSubmitHandler: () => dispatch(onSubmitHandler())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomepageModulesIndex);

/*

O nás   1
Ceník   2
Okolí   3
Galerie 4
Kontakt 5

NAME | VISIBLE | ORDER | EDIT

*/
