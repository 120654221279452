import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";

//   const date = moment(item.from.toDate()).format("D/M YYYY, hh:mm");

import { fetchNotifications } from "../../../store/actions/notificationsActions";

class AdminNotificationsIndex extends Component {
  componentDidMount() {
    this.props.fetchNotifications();
  }
  render() {
    const { notifications } = this.props.notifications;
    const wannabeGrid = notifications.map(item => {
      return (
        <div>
          <span>{item.name}</span>
          <span>{moment(item.from.toDate()).format("D/M YYYY")}</span> {" - "}
          <span>{moment(item.to.toDate()).format("D/M YYYY")}</span>
          <a href={"/admin/notifications/" + item.id}>Detail</a>
        </div>
      );
    });
    return (
      <div>
        Admin notifications index
        <br /> {wannabeGrid}{" "}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications
  };
};

const mapDisptachToProps = dispatch => {
  return {
    fetchNotifications: () => dispatch(fetchNotifications())
  };
};

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(AdminNotificationsIndex);
