const initState = {};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      console.log("Login successed");
      return state;
    case "LOGIN_FAILED":
      console.log("Login failed");
      return state;
    case "SIGNOUT_SUCCESS":
      return state;
    default:
      return state;
  }
};

export default authReducer;
