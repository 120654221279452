import React, { Component } from "react";
import BackButton from "../BackButton/BackButton";
import { connect } from "react-redux";

import AdminHomepageModuleAboutUs from "./AdminHomepageModule/AdminHomepageModuleAboutUs";
import AdminHomepageModuleContacts from "./AdminHomepageModule/AdminHomepageModuleContacts";
import AdminHomepageModuleNearby from "./AdminHomepageModule/AdminHomepageModuleNearby";
import AdminHomepageModulePrices from "./AdminHomepageModule/AdminHomepageModulePrices";
import AdminSpinner from "../../AdminSpinner/AdminSpinner";

import {
  getModuleInfo,
  singleModuleDataChangeHandler,
  singleModuleOnSubmitHandler,
  singleModuleDataChangeHandlerForItems,
  pushNewItemsForSingleModule,
  singleModuleDataChangeHandlerForNewItems,
  deleteModuleItem,
  deleteModuleNewItem
} from "../../../store/actions/modulesActions";

class HomepageModulesDetail extends Component {
  componentDidMount() {
    this.props.getModuleInfo(this.props.id, true);
  }

  inputTypeCheck = e => {
    let valueToHandle;

    if (e.target.type === "checkbox") {
      valueToHandle = e.target.checked;
    } else if (e.target.type === "number") {
      valueToHandle = Number(e.target.value);
    } else {
      valueToHandle = e.target.value;
    }

    return valueToHandle;
  };

  onChangeHandler = e => {
    let valueToHandle = this.inputTypeCheck(e);
    this.props.singleModuleDataChangeHandler(e.target.id, valueToHandle);
  };

  onChangeHandlerForItems = (e, index) => {
    let valueToHandle = this.inputTypeCheck(e);
    this.props.singleModuleDataChangeHandlerForItems(
      e.target.id,
      valueToHandle,
      index
    );
  };

  onChangeHandlerForNewItems = (e, index) => {
    let valueToHandle = this.inputTypeCheck(e);
    this.props.singleModuleDataChangeHandlerForNewItems(
      e.target.id,
      valueToHandle,
      index
    );
  };

  onSubmitHandler = e => {
    e.preventDefault();
    this.props.singleModuleOnSubmitHandler(this.props.id);
  };

  deleteModuleItemHandler = itemId => {
    this.props.deleteModuleItem(this.props.id, itemId);
  };

  deleteModuleNewItemHandler = itemId => {
    this.props.deleteModuleItem(this.props.id, itemId);
  };

  render() {
    const {
      id,
      singleModuleLoading,
      singleModuleData,
      singleModuleError,
      singleModuleIsSubmiting,
      singleModuleItemsData,
      pushNewItemsForSingleModule,
      singleModuleItemsDataAdded,
      onChangeHandlerForNewItems,
      deleteModuleItem
    } = this.props;
    let renderModule = null;

    if (singleModuleLoading) {
      return (
        <div className="admin homepage-modules">
          <div className="heading">
            <BackButton path="/admin/homepage-modules" />
            Modul hlavní stránky
            <span className="hp-module-sction-badge"> . . . </span>
            <AdminSpinner />
          </div>
        </div>
      );
    }

    //console.log(this.props.id);

    switch (id) {
      case "about-us":
        return (
          <AdminHomepageModuleAboutUs
            moduleData={singleModuleData}
            onChangeHandler={this.onChangeHandler}
            onSubmitHandler={this.onSubmitHandler}
            isSubmiting={singleModuleIsSubmiting}
          />
        );
      case "contacts":
        return (
          <AdminHomepageModuleContacts
            moduleData={singleModuleData}
            onChangeHandler={this.onChangeHandler}
            onChangeHandlerForItems={this.onChangeHandlerForItems}
            onSubmitHandler={this.onSubmitHandler}
            isSubmiting={singleModuleIsSubmiting}
            items={singleModuleItemsData}
            /* -- */
            addNewItemHandler={newItemConfig =>
              pushNewItemsForSingleModule(newItemConfig)
            }
            singleModuleItemsDataAdded={singleModuleItemsDataAdded}
            onDeleteHandler={itemId => this.deleteModuleItemHandler(itemId)}
            deleteModuleNewItem={itemPosition =>
              this.props.deleteModuleNewItem(itemPosition)
            }
            onChangeHandlerForNewItems={(e, index) =>
              this.onChangeHandlerForNewItems(e, index)
            }
          />
        );
      case "nearby":
        return (
          <AdminHomepageModuleNearby
            moduleData={singleModuleData}
            onChangeHandler={this.onChangeHandler}
            onChangeHandlerForItems={this.onChangeHandlerForItems}
            onSubmitHandler={this.onSubmitHandler}
            isSubmiting={singleModuleIsSubmiting}
            items={singleModuleItemsData}
            onChangeHandlerForItems={(e, index) =>
              this.onChangeHandlerForItems(e, index)
            }
            /* -- */
            addNewItemHandler={newItemConfig =>
              pushNewItemsForSingleModule(newItemConfig)
            }
            singleModuleItemsDataAdded={singleModuleItemsDataAdded}
            onDeleteHandler={itemId => this.deleteModuleItemHandler(itemId)}
            deleteModuleNewItem={itemPosition =>
              this.props.deleteModuleNewItem(itemPosition)
            }
            onChangeHandlerForNewItems={(e, index) =>
              this.onChangeHandlerForNewItems(e, index)
            }
          />
        );
      case "prices":
        return (
          <AdminHomepageModulePrices
            moduleData={singleModuleData}
            onChangeHandler={this.onChangeHandler}
            onChangeHandlerForItems={(e, index) =>
              this.onChangeHandlerForItems(e, index)
            }
            onChangeHandlerForNewItems={(e, index) =>
              this.onChangeHandlerForNewItems(e, index)
            }
            onSubmitHandler={this.onSubmitHandler}
            isSubmiting={singleModuleIsSubmiting}
            items={singleModuleItemsData}
            /* -- */
            addNewItemHandler={newItemConfig =>
              pushNewItemsForSingleModule(newItemConfig)
            }
            singleModuleItemsDataAdded={singleModuleItemsDataAdded}
            onDeleteHandler={itemId => this.deleteModuleItemHandler(itemId)}
            deleteModuleNewItem={itemPosition =>
              this.props.deleteModuleNewItem(itemPosition)
            }
          />
        );
      default:
        return (
          <div className="admin homepage-modules">
            <div className="heading">
              <BackButton path="/admin/homepage-modules" />
              Modul hlavní stránky
            </div>
            <div className="content__inner">
              <div className="hp-module-detail">
                <h3>Tento modul neexistuje</h3>
              </div>
            </div>
          </div>
        );
    }
  }
}

const mapStateToProps = state => {
  return {
    singleModuleError: state.modules.singleModuleError,
    singleModuleData: state.modules.singleModuleData,
    singleModuleLoading: state.modules.singleModuleLoading,
    singleModuleIsSubmiting: state.modules.singleModuleIsSubmiting,
    singleModuleItemsData: state.modules.singleModuleItemsData,
    singleModuleItemsDataAdded: state.modules.singleModuleItemsDataAdded
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getModuleInfo: (moduleId, forceLoading) =>
      dispatch(getModuleInfo(moduleId, forceLoading)),
    singleModuleDataChangeHandler: (targetId, value) =>
      dispatch(singleModuleDataChangeHandler(targetId, value)),
    singleModuleOnSubmitHandler: moduleId =>
      dispatch(singleModuleOnSubmitHandler(moduleId)),
    singleModuleDataChangeHandlerForItems: (targetId, value, index) =>
      dispatch(singleModuleDataChangeHandlerForItems(targetId, value, index)),
    singleModuleDataChangeHandlerForNewItems: (targetId, value, index) =>
      dispatch(
        singleModuleDataChangeHandlerForNewItems(targetId, value, index)
      ),
    pushNewItemsForSingleModule: newItemConfig =>
      dispatch(pushNewItemsForSingleModule(newItemConfig)),
    deleteModuleItem: (moduleId, itemId) =>
      dispatch(deleteModuleItem(moduleId, itemId)),
    deleteModuleNewItem: itemPosition =>
      dispatch(deleteModuleNewItem(itemPosition))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomepageModulesDetail);
