import React from "react";

const AdminSpinner = props => {
  return (
    <div
      className={
        props.full ? "spinner-wraper full-container" : "spinner-wraper"
      }
    >
      <span className="text">{props.text ? props.text : "Načítání"}</span>
      <span className="spinner" />
    </div>
  );
};

export default AdminSpinner;
