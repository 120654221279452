export const cmsGalleryPage = itemsPerPage => {
  // lastVisible = lastVisible ? lastVisible : "";
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "INIT_GALLERY_ITEMS" });
    const firestore = getFirestore();
    // const d = new Date();
    // console.log("nevimus", getState().cmsGalleryPageReducer.lastId);
    const lastVisible = getState().cmsGalleryPageReducer.lastId
      ? getState().cmsGalleryPageReducer.lastId
      : "";
    console.log("LASTTTTTTTTTTTTT ---", lastVisible);
    firestore
      .collection("cmsContentGallery")
      .orderBy("uploadTime", "desc")
      .startAfter(lastVisible)
      .limit(itemsPerPage)
      .get()
      .then(res => {
        let images = [];
        // console.log("rsX", res);
        res.docs.forEach(item => {
          const row = item.data();
          row.id = item.id;
          images.push(row);
          console.log("fetched", row);
        });
        dispatch({ type: "SUC_GALLERY_ITEMS", images });
      })
      .catch(err => {
        console.log("!!", err);
      });
    //   dispatch({ type: "SAVE_GALLERY_PICKED_FILE_INIT" });
    //   const realData = { ...getState().cmsGalleryReducer.selectedFileDetail };
    //   const { id, ...modifedRealData } = realData;
    //   firestore
    //     .collection("cmsContentGallery")
    //     .doc(id)
    //     .update(modifedRealData)
    //     .then(() => {
    //       dispatch({ type: "SAVE_GALLERY_PICKED_FILE_SUC" });
    //       dispatch(cmsGetImages());
    //     });
  };
};
