const initState = {
  uploadingLoading: false,
  uploadingFiles: [],
  loadingFilesInBucket: false,
  uploadedFilesInBucket: [],
  selectedFileDetail: null,
  loadingSelectedFileDetail: false,
  galleryModuleGlobal: null,
  galleryModuleGlobalIsLoading: true,
  //deleteFile
  deletingFileIsActive: false,
  deletingFileError: false,
  deletingFileSuccess: false
  //deleteFile
};

const cmsGalleryReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_GALLERY_MODULE_GLOBAL_INIT":
      return {
        ...state,
        galleryModuleGlobalIsLoading: true
      };
    case "GET_GALLERY_MODULE_GLOBAL_SUC":
      return {
        ...state,
        galleryModuleGlobalIsLoading: false,
        galleryModuleGlobal: action.globalConfig
      };
    case "UPDATE_UPLOADED_FILES":
      return {
        ...state,
        uploadingLoading: false,
        uploadingFiles: [...state.uploadingFiles, action.uploadingFiles]
      };
    case "GET_FILES_IN_BUCKET_START": {
      return {
        ...state,
        uploadingFiles: false,
        uploadingFiles: [],
        uploadedFilesInBucket: []
      };
    }
    case "CLEAR_UPLOADING_FILES":
      return {
        ...state,
        uploadingFiles: []
      };
    case "GET_FILES_IN_BUCKET_COMPLETE":
      return {
        ...state,
        uploadedFilesInBucket: action.files
      };
    case "GET_FILE_DETAIL_INFO_START":
      return {
        ...state,
        loadingSelectedFileDetail: true
      };
    case "GET_FILE_DETAIL_INFO_SUC":
      return {
        ...state,
        loadingSelectedFileDetail: false,
        selectedFileDetail: action.fileObj
      };
    case "UPDATE_GALLERY_GLOBAL_CONFIG":
      const newGalleryModuleGlobal = { ...state.galleryModuleGlobal };
      newGalleryModuleGlobal[action.id] = action.value;
      return {
        ...state,
        galleryModuleGlobal: newGalleryModuleGlobal
      };
    case "UPDATE_GALLERY_PICKED_FILE":
      const newSelectedFileDetail = { ...state.selectedFileDetail };
      newSelectedFileDetail[action.id] = action.value;
      return {
        ...state,
        selectedFileDetail: newSelectedFileDetail
      };
    case "DETAIL_NULL":
      return {
        ...state,
        selectedFileDetail: null
      };
    case "DELETE_FILE_START":
      return {
        ...state,
        deletingFileIsActive: true,
        deletingFileSuccess: false,
        deletingFileError: false
      };
    case "DELETE_FILE_SUCCESS":
      return {
        ...state,
        deletingFileIsActive: false,
        deletingFileSuccess: true,
        deletingFileError: false
      };
    case "DELETE_FILE_FAILED":
      return {
        ...state,
        deletingFileIsActive: false,
        deletingFileSuccess: false,
        deletingFileError: true
      };
    case "DELETE_FILE_CLEAR":
      return {
        ...state,
        deletingFileIsActive: false,
        deletingFileSuccess: false,
        deletingFileError: false
      };
    default:
      return state;
  }
};

export default cmsGalleryReducer;
