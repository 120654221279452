import React from 'react'
import HomepageSection from '../HomepageSection'

const HomepageContacts = props => {
  if (!props.moduleContactsGlobal.isVisible) {
    return null
  }
  const iconNav = (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 471 471">
      <g>
        <g>
          <path d="M317,471l-72-217H9L462,0L317,471z" />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )

  const nicePhone = phone => {
    let nicePhone = '+'
    for (let x = 1; x < 13; x++) {
      nicePhone = nicePhone + phone[x]
      if (x % 3 === 0) {
        nicePhone = nicePhone + ' '
      }
    }
    return nicePhone
  }

  const contactsItemsRender = props.moduleContacts.map((item, index) => {
    const itemValue = item.icon !== 'phone' ? item.value : nicePhone(item.value)
    return (
      <div className="contact-item" key={'contact-item-' + index}>
        <i className={'fas fa-' + item.icon} />
        <span>{itemValue}</span>
      </div>
    )
  })

  return (
    <HomepageSection title={props.moduleContactsGlobal.name}>
      <div className="homepage-contacts">
        {contactsItemsRender}
        <div className="contact-item" style={{ flex: '1 0 100%' }}>
          <i class="fas fa-money-check"></i>
          <span>
            <b style={{ marginRight: '.25rem' }}>
              Účet pro platby na rezervace:{' '}
            </b>
            115-7464100247/0100
          </span>
        </div>
      </div>
      <div className="find-us">
        <div className="heading">{iconNav}</div>
        <iframe
          title="map"
          src={props.moduleContactsGlobal.mapSrcUrl}
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: '0' }}
          allowfullsscreen="true"
        />
      </div>
    </HomepageSection>
  )
}

export default HomepageContacts
