const initState = {
  notifications: []
};

const notificationsReducer = (state = initState, action) => {
  switch (action.type) {
    case "INIT_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        notifications: action.notifications
      };
    default:
      return state;
  }
};

export default notificationsReducer;
