import React, { useState, useEffect } from "react";
import ScreenMenu from "../Menu/ScreenMenu";
import { Icon, Input, Select } from "antd";
import { getFirestore } from "redux-firestore";
import { DatePicker } from "antd";
import Footer from "../Footer/Footer";

const { RangePicker } = DatePicker;
const { Option } = Select;

const datePickerLocale = {
  lang: {
    rangePlaceholder: ["Datum Od", "Datum Do"],
    yearFormat: "YYYY"
  }
};

const peopleSelect = () => {
  const renderOptions = () => {
    let options = [];
    for (let x = 1; x < 22; x++) {
      options.push(<Option value={x}>{x}</Option>);
    }
    return options.map(option => option);
  };
  return (
    <Select defaultValue="select" onChange={peopleOnChange}>
      <Option value="select" disabled>
        Počet osob
      </Option>
      {renderOptions()}
    </Select>
  );
};

const peopleOnChange = () => {
  console.log("change");
};

const dateOnChange = () => {
  console.log("change");
};

const Reservation = () => {
  const [showIframe, setShowIframe] = useState(false);
  return (
    <div className="about-page single-page">
      <ScreenMenu miniActive />
      <div className="container">
        <h1>Rezervace</h1>
        <p class="text-center mb-4">
          Momentálně si rezervaci můžete udělat třemi způsoby
        </p>
        <div className="reservation-types">
          <div className="reservation-type">
            <div className="r-h">
              <h3>Zaslání formuláře</h3>
              <p>
                Zašlete nám formulář ohledně ubytování a my se vám ozveme
                ohledně ceny, dostupnosti a potvrzení
              </p>
            </div>
            <div className="content r-f">
              {showIframe ? (
                <div
                  className="showiframe"
                  onClick={() => setShowIframe(false)}
                >
                  <Icon type="eye-invisible" />
                  Schovat přibližnou dostupnost
                </div>
              ) : (
                <div className="showiframe" onClick={() => setShowIframe(true)}>
                  <Icon type="eye" />
                  Zobrait přibližnou dostupnost
                </div>
              )}
              <iframe
                style={{
                  height: showIframe ? "400px" : "0px",
                  paddingTop: !showIframe && "0px"
                }}
                class="iframe-res"
                src="https://obsazenost.e-chalupy.cz/kalendarNew.php?id=4849&pocetMesicu=20&obsazenoPozadi=FF7F6B&castecnePozadi=5fbfda&volnoPozadi=60e588&velikost=2&legenda=ano"
              />
              <form>
                <Input size="large" placeholder="Jméno" />
                <Input size="large" placeholder="Příjmení" />
                {peopleSelect()}
                <RangePicker
                  onChange={dateOnChange}
                  separator="-"
                  format="DD/MM/YYYY"
                  locale={datePickerLocale}
                />
                <Input size="large" placeholder="Kontaktní email/telefon" />
                <button className="submit">Odeslat</button>
              </form>
            </div>
          </div>
          <div className="reservation-type">
            <div className="r-h">
              <h3>Kontakujte nás</h3>
              <p>
                Kontakujte nás telefonicky na čísle <b>+420 222 532 547</b>,
                nebo nám napiště email na{" "}
                <b>
                  <a href="#">jizersky-penzion@penzion.cz</a>
                </b>
              </p>
            </div>
          </div>
          <div className="reservation-type">
            <div className="r-h">
              <h3>Online portál</h3>
              <p>Zajistěte si rezervaci přes online portál</p>
              <div className="online">
                <div>
                  <a
                    target="_blank"
                    href="https://www.hotel.cz/reservation/jizersky-penzion/"
                  >
                    Hotel.cz
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://jizersky-penzion.penzion.cz/"
                  >
                    Penzion.cz
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://www.booking.com/hotel/cz/jizersky-pension.cs.html"
                  >
                    Booking.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Reservation;
