import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      (C) 2018 - {new Date().getFullYear()} Jizerský Pension
      <section className="eet-section">
        Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu
        účtenku. Zároveň je povinen zaevidovat přijatou tržbu u správce daně
        online; v případě technického výpadku pak nejpozději do 48 hodin.
      </section>
    </footer>
  );
};

export default Footer;
