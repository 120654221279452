import React, { useState } from "react";
import HomepageSection from "../HomepageSection";
import FsLightbox from "fslightbox-react";

const HomepageGallery = props => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  var urlsToLightbox = props.moduleGalleryContent
    .filter((item, index) => item.isVisible && index < 6)
    .map(item => item.url);

  // console.log("urlks", urlsToLightbox);

  // const typesHack = () => {
  //   const value = urlsToLightbox.map(item => "image");
  //   return value;
  // };

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }

  if (!props.moduleGalleryGlobal.isVisible) {
    return null;
  }

  const galleryRender = props.moduleGalleryContent
    .filter(item => item.isVisible)
    .map((item, index) => {
      if (item.isVisible && index < 6) {
        return (
          <div
            key={item.url}
            className="gallery-item"
            onClick={() => openLightboxOnSlide(index + 1)}
          >
            <img src={item.url} />
            <span className="desc">{item.description}</span>
          </div>
        );
      }
    });
  return (
    <HomepageSection title={props.moduleGalleryGlobal.name}>
      <div className="gallery-items">{galleryRender}</div>
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        // type={() => typesHack()}
        // urls={urlsToLightbox}
        sources={urlsToLightbox}
        type="image"
      />
    </HomepageSection>
  );
};

export default HomepageGallery;
