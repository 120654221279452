import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

const ScreenMenu = props => {
  const [burgerActive, setBurgerActive] = useState(false);

  const burgerToggler = () => {
    setBurgerActive(!burgerActive);
  };

  const clickHandler = () => {
    setBurgerActive(false);
  };

  const menuItems = [
    { text: "O nás", url: "/#o-nas", class: null },
    { text: "Okolí", url: "/#okoli", class: null },
    {
      text: "Informace o ubytování",
      url: "/informace-o-ubytovani",
      class: null
    },
    { text: "Ceník", url: "/#cenik", class: null },
    { text: "Kontakt", url: "/#kontakt", class: null },
    { text: "Rezervace", url: "/rezervace", class: "orange" }
  ];
  const renderMenuItems = menuItems.map((item, index) => {
    return (
      <li key={"menu-item-" + index} onClick={() => clickHandler()}>
        <HashLink
          className={item.class !== null ? item.class : null}
          to={item.url}
        >
          {item.text}
        </HashLink>
      </li>
    );
  });
  return (
    <section className={props.miniActive ? "screen-menu mini" : "screen-menu"}>
      <h1>Jizerský Pension</h1>
      <span
        className={burgerActive ? "burger active" : "burger"}
        onClick={() => burgerToggler()}
      >
        <span className="line" />
      </span>
      <ul>{renderMenuItems}</ul>
      <div className="main-menu__bg" />
    </section>
  );
};

export default ScreenMenu;
