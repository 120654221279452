import React from "react";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";

const AdminDashboard = props => {
  if (!props.auth.uid) return <Redirect to="/admin" />;
  return (
    <div className="admin admin-dashboard">
      <div className="side-menu">
        <nav className="aside-menu__nav">
          <NavLink to="/admin/dashboard">Dashboard</NavLink>
          <NavLink to="/admin/homepage-modules">Moduly hlavní stránky</NavLink>
        </nav>
      </div>
      <div className="dashboard-content">HP Modules</div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapStateToProps)(AdminDashboard);
