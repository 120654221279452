import authReducer from "./authReducer";
import modulesReducer from "./modulesReducer";
import homepageReducer from "./homepageReducer";
import cmsGalleryReducer from "./cmsGalleryReducer";
import cmsInfoReducer from "./cmsInfoReducer";
import notificationsReducer from "./notificationsReducer";
import settingsReducer from "./settingsReducer";
import cmsGalleryPageReducer from "./cmsGalleryPageReducer";
import versionReducer from "./versionReducer";

import { combineReducers } from "redux";

import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer,
  modules: modulesReducer,
  homepageReducer: homepageReducer,
  cmsGalleryReducer: cmsGalleryReducer,
  cmsInfoReducer: cmsInfoReducer,
  notifications: notificationsReducer,
  settings: settingsReducer,
  cmsGalleryPageReducer: cmsGalleryPageReducer,
  versionReducer: versionReducer
});

export default rootReducer;
