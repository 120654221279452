import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// export const admin = require("firebase-admin");

var config = {
  apiKey: "AIzaSyCYY87Pr-OsVi4zj9_dvubOFnQle9C4BzA",
  authDomain: "jizersky-pension.firebaseapp.com",
  databaseURL: "https://jizersky-pension.firebaseio.com",
  projectId: "jizersky-pension",
  storageBucket: "gs://jizersky-pension.appspot.com", //jizersky-pension.appspot.com
  messagingSenderId: "507644201103"
};
firebase.initializeApp(config);
firebase.storage();
firebase.firestore();

export default firebase;
