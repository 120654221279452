import firebase from "../../config/firebase";

export const cmsGetGalleryModuleGlobal = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "GET_GALLERY_MODULE_GLOBAL_INIT" });
    const firestore = getFirestore();
    firestore
      .collection("homepageModules")
      .doc("gallery")
      .get()
      .then(res => {
        dispatch({
          type: "GET_GALLERY_MODULE_GLOBAL_SUC",
          globalConfig: res.data()
        });
      });
  };
};

export const cmsGallerySetDetailNull = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "DETAIL_NULL" });
  };
};

export const cmsGalleryUpdateGlobalConfig = (id, value) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "UPDATE_GALLERY_GLOBAL_CONFIG",
      id,
      value
    });
  };
};

export const cmsGalleryUpdateGalleryPickedFile = (id, value) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "UPDATE_GALLERY_PICKED_FILE",
      id,
      value
    });
  };
};

export const cmsGallerySaveGalleryPickedFile = id => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SAVE_GALLERY_PICKED_FILE_INIT" });
    const firestore = getFirestore();
    const realData = { ...getState().cmsGalleryReducer.selectedFileDetail };
    const { id, ...modifedRealData } = realData;
    firestore
      .collection("cmsContentGallery")
      .doc(id)
      .update(modifedRealData)
      .then(() => {
        dispatch({ type: "SAVE_GALLERY_PICKED_FILE_SUC" });
        dispatch(cmsGetImages());
      });
  };
};

export const cmsGallerySaveGalleryGlobal = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SAVE_GALLERY_GLOBAL_CONFIG_INIT" });
    const firestore = getFirestore();
    firestore
      .collection("homepageModules")
      .doc("gallery")
      .update(getState().cmsGalleryReducer.galleryModuleGlobal)
      .then(() => {
        dispatch({ type: "SAVE_GALLERY_GLOBAL_CONFIG_SUC" });
      });
  };
};

export const cmsGetImages = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "GET_FILES_IN_BUCKET_START" });
    const firestore = getFirestore();
    firestore
      .collection("cmsContentGallery")
      .orderBy("uploadTime", "desc")
      .get()
      .then(res => {
        const files = res.docs.map(item => {
          const itemWithId = item.data();
          itemWithId.id = item.id;
          return itemWithId;
        });
        dispatch({
          type: "GET_FILES_IN_BUCKET_COMPLETE",
          files: files
        });
      });
  };
};

export const cmsGetFileDetailInfo = id => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "GET_FILE_DETAIL_INFO_START" });
    const firestore = getFirestore();
    firestore
      .collection("cmsContentGallery")
      .doc(id)
      .get()
      .then(res => {
        const fileObject = res.data();
        fileObject.id = res.id;
        dispatch({ type: "GET_FILE_DETAIL_INFO_SUC", fileObj: fileObject });
      })
      .catch(err => console.log(err));
  };
};

const saveFileToDabase = (file, firestore) => {
  const obj = {
    name: file.name,
    url: file.url,
    size: file.size,
    type: file.type,
    description: "",
    alt: "",
    order: 0,
    uploadTime: new Date(),
    isVisible: false
  };
  firestore.collection("cmsContentGallery").add(obj);
};

const fileIsExisting = async (name, firestore) => {
  let length;
  const result = await firestore
    .collection("cmsContentGallery")
    .where("name", "==", name)
    .get()
    .then(res => {
      length = res.docs.length;
      return true;
    });
  console.log(" -------------- omfg --------------", length);
  // return result == 0;
};

export const clearUploadingFiles = () => {
  return dispatch => {
    dispatch({ type: "CLEAR_UPLOADING_FILES" });
  };
};

export const cmsUploadFiles = filesToUpload => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // // console.log("moje neco", images);

    // const fb = getFirebase();
    // const storage = fb.storage();

    // //check if file doesnt exist yet//
    // const onResolve = async (item, number = 1) => {
    //   const imageName = item.name.split(".")[0];
    //   const imageType = item.name.split(".")[1];
    //   const imageNumberOfCopy = number;
    //   const imageWholeName = `${imageName}.${imageType} (${imageNumberOfCopy})`;
    //   const newItem = new File([item], `${imageWholeName}`);

    //   const rec = async (item, number) => {
    //     storage
    //       .ref(`images/${imageWholeName}`)
    //       .getDownloadURL()
    //       .then(
    //         async url => {
    //           await onResolve(item, number);
    //           console.log("hmmm", images);
    //           return true;
    //         },
    //         err => {
    //           return false;
    //         }
    //       );
    //   };

    //   // while (rec(item, number)) {
    //   //   rec(item, number);
    //   //   console.log(item, number);
    //   // }

    //   return newItem;

    //   console.log("konec?", item, number);

    //   // console.log("hmmm", url, item, newItem);
    //   // images[index] = newItem;
    //   // return newItem;

    //   //udelana kopie
    //   //podivat se jestli kopie taky neexistuje

    //   //pokud existuje tak zjistit číslo a přičíst další
    //   // pokud taky existuje znovu proces
    //   // uložit
    // };

    // await images.map((item, index) => {
    //   storage
    //     .ref(`images/${item.name}`)
    //     .getDownloadURL()
    //     .then(
    //       async url => {
    //         images[index] = await onResolve(item);
    //         console.log("hmmm", images);
    //         return await onResolve(item);
    //       },
    //       err => {
    //         return;
    //       }
    //     );
    // });

    // console.log("IMAGES", images);

    // const namesX = [
    //   "photo",
    //   "photo (1)",
    //   "photo (2)",
    //   "photo (3)",
    //   "photo (4)",
    //   "photo (5)"
    // ];

    // const nameX = "photo";

    // const doStuff = nameX => {

    // }

    // return false;

    const images = [...filesToUpload];

    const fb = getFirebase();
    const storage = fb.storage();

    const imageExists = name => {
      return storage
        .ref(`images/${name}`)
        .getDownloadURL()
        .then(
          url => {
            return true;
          },
          err => {
            return false;
          }
        );
    };

    const makeImageNameCopy = async (item, numberOfCopy = 1) => {
      const imageName = item.name.split(".")[0];
      const imageType = item.name.split(".")[1];
      const imageNumberOfCopy = numberOfCopy;
      const imageWholeName = `${imageName}.${imageType} (${imageNumberOfCopy})`;

      const isImageExists = await imageExists(item.name);

      let output = "";

      if (imageNumberOfCopy == 1) {
        if (isImageExists) {
          output = await makeImageNameCopy(item, imageNumberOfCopy + 1);
        } else {
          output = imageWholeName;
        }
      } else {
        const isImageExists = await imageExists(imageWholeName);
        if (isImageExists) {
          output = await makeImageNameCopy(item, imageNumberOfCopy + 1);
        } else {
          output = imageWholeName;
        }
      }
      return output;
    };

    var bar = new Promise((resolve, reject) => {
      images.forEach(async (item, index) => {
        const isImageExists = await imageExists(item.name);
        if (isImageExists) {
          const imageCopyName = await makeImageNameCopy(item);
          images[index] = new File([item], `${imageCopyName}`);
          console.log("picture exists..making copy - ", imageCopyName);
        }
        if (index === images.length - 1) resolve();
      });
    });

    bar.then(() => {
      let uploadTask = images.map(image => {
        console.log("put iamge..", image);
        return storage.ref(`images/${image.name}`).put(image);
      });

      uploadTask.forEach((task, index) => {
        task.on(
          "state_changed",
          snapshot => {
            console.log("only snap", snapshot);
          },
          error => {
            console.log("ERROR", error);
          },
          res => {
            console.log("completed", task);
            console.log("BLOB", task.blob_.data_.name);
            //complete fc
            storage
              .ref("images")
              .child(task.blob_.data_.name)
              .getDownloadURL()
              .then(url => {
                images[index]["url"] = url;
                saveFileToDabase(images[index], getFirestore());
                dispatch({
                  type: "UPDATE_UPLOADED_FILES",
                  uploadingFiles: images[index]
                });
                // dispatch(cmsGetImages());
              });
          }
        );
      });

      console.log("upladoing...");
    });
  };
};

export const cmsInfoGetGlobalConfig = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "GET_INFO_MODULE_GLOBAL_INIT" });
    const firestore = getFirestore();
    firestore
      .collection("cmsPages")
      .doc("info")
      .get()
      .then(res => {
        dispatch({
          type: "GET_INFO_MODULE_GLOBAL_SUC",
          globalConfig: res.data()
        });
      });
  };
};

export const cmsInfoUpdateGlobalConfig = (id, value) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "UPDATE_INFO_GLOBAL_CONFIG",
      id,
      value
    });
  };
};

export const cmsInfoSaveGlobalConfig = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SAVE_INFO_GLOBAL_CONFIG_INIT" });
    const firestore = getFirestore();
    firestore
      .collection("cmsPages")
      .doc("info")
      .update(getState().cmsInfoReducer.moduleGlobalConfig)
      .then(() => {
        dispatch({ type: "SAVE_INFO_GLOBAL_CONFIG_SUC" });
        setTimeout(() => {
          dispatch({ type: "INFO_GLOBAL_CONFIG_CLEAR_SAVED" });
        }, 3000);
      });
  };
};

export const cmsInfoGetModuleItems = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "GET_INFO_MODULE_ITEMS_INIT" });
    const firestore = getFirestore();
    firestore
      .collection("cmsContentInfo")
      .orderBy("createdAt")
      .get()
      .then(res => {
        const items = res.docs.map((item, index) => {
          const modifedItem = { ...item.data() };
          modifedItem.id = item.id;
          return modifedItem;
        });
        dispatch({
          type: "GET_INFO_MODULE_ITEMS_SUC",
          items
        });
      });
  };
};

export const cmsInfoModulePushNewItem = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const item = {
      createdAt: new Date(),
      description: "",
      icon: "",
      name: "",
      order: 0
    };
    console.log("puhsing new item...", item);
    dispatch({
      type: "INFO_PUSH_NEW_ITEM",
      item
    });
  };
};

export const cmsInfoUpdateExistingItem = (id, idOfItem, value) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "INFO_UPDATE_EXISTING_ITEM",
      idOfItem,
      id,
      value
    });
  };
};

export const cmsInfoSaveSingleItem = item => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const { id, ...rest } = item;
    const itemWithoutId = rest;

    dispatch({ type: "INFO_CMS_UPDATE_SINGLE_ITEM_INIT", id: item.id });

    const firestore = getFirestore();
    firestore
      .collection("cmsContentInfo")
      .doc(id)
      .update(itemWithoutId)
      .then(res => {
        dispatch({ type: "INFO_CMS_UPDATE_SINGLE_ITEM", item });
      })
      .catch(err => {
        console.log("err", err);
      });
  };
};

export const cmsInfoUpdateNewItem = (position, id, value) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "INFO_UPDATE_NEW_ITEM",
      position,
      id,
      value
    });
  };
};

export const cmsInfoDeleteNewItem = position => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "INFO_DELETE_NEW_ITEM",
      position
    });
  };
};

export const cmsAddDeletedItem = id => {
  return (dispatch, { getFirestore }) => {
    dispatch({ type: "INFO_CMS_UPDATE_DELETED_ITEMS", id });
  };
};

export const cmsSaveInfoContent = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: "START_SAVE_INFO_CONTENT" });
    const firestore = getFirestore();

    let promises = [];

    const items = getState().cmsInfoReducer.cmsInfoItems;

    items.forEach(item => {
      const { id, ...rest } = item;
      const itemWithoutId = rest;
      promises.push(
        firestore
          .collection("cmsContentInfo")
          .doc(id)
          .update(itemWithoutId)
      );
    });

    const newlyAddedItems = getState().cmsInfoReducer.cmsInfoItemsNew;

    if (newlyAddedItems.length > 0) {
      newlyAddedItems.forEach(item => {
        promises.push(firestore.collection("cmsContentInfo").add(item));
      });
    }

    const deletedItemsIds = getState().cmsInfoReducer.cmsInfoDeletedItems;

    if (deletedItemsIds.length > 0) {
      deletedItemsIds.forEach(id => {
        promises.push(
          firestore
            .collection("cmsContentInfo")
            .doc(id)
            .delete()
        );
      });
    }

    Promise.all(promises)
      .then(() => {
        dispatch(cmsInfoGetModuleItems());
      })
      .catch(err => {
        console.log("error", err);
      });
  };
};

export const clearDeletingData = () => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({ type: "DELETE_FILE_CLEAR" });
  };
};

export const deleteFileFromStorage = (name, id) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    // Get a reference to the storage service, which is used to create references in your storage bucket
    var storage = firebase.storage();
    // Create a storage reference from our storage service
    var storageRef = storage.ref();
    // Create a reference to the file to delete
    var desertRef = storageRef.child(`images/${name}`);

    dispatch({ type: "DELETE_FILE_START" });

    // Delete the file
    desertRef
      .delete()
      .then(function(e) {
        firestore
          .collection("cmsContentGallery")
          .doc(id)
          .delete();
        // File deleted successfully
        dispatch({ type: "DELETE_FILE_SUCCESS" });
        console.log("succ", e);
      })
      .catch(function(e) {
        dispatch({ type: "DELETE_FILE_FAILED" });
        console.log("fail", e);
        // Uh-oh, an error occurred!
      });
    // console.log("delete images/" + name);
  };
};
