import React from "react";
import { Checkbox, Input, InputNumber } from "antd";

const onChange = e => {
  let id, value;
  if (e.target.type === "text" || "number") {
    id = e.target.id;
    value = e.target.value;
  } else if (e.target.type === "checkbox" || "radio") {
    id = e.target.id;
    value = e.target.checked;
  }
};

const Try = () => {
  return (
    <div>
      <Checkbox id="checkid" onChange={onChange} />
      <Input
        id="norminputid"
        placeholder="Basic usage"
        value="s"
        onChange={onChange}
      />
      <Input
        id="numinputid"
        placeholder="Add number"
        type="number"
        onChange={e => onChange(e)}
      />
      <InputNumber id="wtf" onChange={e => onChange(e)} />
    </div>
  );
};

export default Try;
