import React, { Component } from "react";
import AdminCmsPageGallery from "./AdminCmsPageGallery";
import AdminCmsPageInfo from "./AdminCmsPageInfo";

class AdminCmsPage extends Component {
  renderCmsPageEdit() {
    switch (this.props.id) {
      case "gallery":
        return <AdminCmsPageGallery />;
      case "info":
        return <AdminCmsPageInfo />;
      default:
        return <AdminCmsPageGallery />;
    }
  }
  render() {
    return <div> {this.renderCmsPageEdit()} </div>;
  }
}

export default AdminCmsPage;
