export const fetchModules = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "INIT_MODULES" });
    const firestore = getFirestore();
    firestore
      .collection("homepageModules")
      .get()
      .then(res => {
        const modules = res.docs.map(module => {
          let actualModule = module.data();
          actualModule = { ...actualModule, id: module.id };
          return actualModule;
        });
        const modules2 = res.docs.map(module => {
          let actualModule = module.data();
          actualModule = { ...actualModule, id: module.id };
          return actualModule;
        });
        dispatch({ type: "INIT_MODULES_LOADED", modules, modules2 });
      })
      .catch(err => {
        dispatch({ type: "INIT_MODULES_FAIL", err });
      });
  };
};

export const moduleChangerHandler = (moduleId, id, value) => {
  return dispatch => {
    dispatch({ type: "MODULES_DATA_CHANGE", moduleId, id, value });
  };
};

const compare = (obj1, obj2) => {
  let changedProperties = [];

  let obj = {};

  for (const key in obj1) {
    if (
      obj1.hasOwnProperty(key) &&
      obj2.hasOwnProperty(key) &&
      obj1[key] !== obj2[key]
    ) {
      //changedProperties.push(key);
      obj[key] = obj1[key];
    }
  }
  if (!(JSON.stringify(obj) === JSON.stringify({}))) {
    changedProperties.push(obj);
  }
  obj = {};

  //console.log("To bude víc fajn >> ", changedProperties);
  return changedProperties;
};

export const singleModuleDataChangeHandler = (targetId, value) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SINGLE_MODULE_DATA_CHANGE", targetId, value });
  };
};

export const singleModuleDataChangeHandlerForNewItems = (
  targetId,
  value,
  index
) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "SINGLE_MODULE_DATA_CHANGE_FOR_NEW_ITEMS",
      targetId,
      value,
      index
    });
  };
};

export const singleModuleDataChangeHandlerForItems = (
  targetId,
  value,
  index
) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "SINGLE_MODULE_DATA_CHANGE_FOR_ITEMS",
      targetId,
      value,
      index
    });
  };
};

export const singleModuleOnSubmitHandler = moduleId => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SINGLE_MODULE_SUBMIT_START" });
    const firestore = getFirestore();
    // console.log("tak jdeme", getState().modules.singleModuleData, moduleId);
    if (moduleId === "about-us") {
      firestore
        .collection("homepageModules")
        .doc(moduleId)
        .update(getState().modules.singleModuleData)
        .then(() => {
          dispatch({ type: "SINGLE_MODULE_SUBMIT_SUC" });
        })
        .catch(err => {
          dispatch({ type: "SINGLE_MODULE_SUBMIT_ERR", err });
        });
    } else {
      // others

      const { singleModuleItemsData } = getState().modules;
      // console.log("??", singleModuleItemsData);

      const promises = [];

      const readyData = singleModuleItemsData.map(item => {
        const { id, ...withoutId } = item;
        return withoutId;
      });

      promises.push(
        firestore
          .collection("homepageModules")
          .doc(moduleId)
          .update(getState().modules.singleModuleData)
      );

      singleModuleItemsData.forEach((item, index) => {
        promises.push(
          firestore
            .collection(
              "homepageModules" +
                moduleId.charAt(0).toUpperCase() +
                moduleId.slice(1) +
                "Items"
            )
            .doc(item.id)
            .update(readyData[index])
        );
      });

      const newlyAddedItems = getState().modules.singleModuleItemsDataAdded;

      if (newlyAddedItems.length > 0) {
        console.log(newlyAddedItems);
        newlyAddedItems.forEach(newItem => {
          console.log("items????", newItem);
          promises.push(
            firestore
              .collection(
                "homepageModules" +
                  moduleId.charAt(0).toUpperCase() +
                  moduleId.slice(1) +
                  "Items"
              )
              .add(
                //createdAt: new Date(),
                newItem
              )
          );
        });
      }

      Promise.all(promises)
        .then(() => {
          dispatch({ type: "SINGLE_MODULE_SUBMIT_SUC" });
          dispatch(getModuleInfo(moduleId, true));
        })
        .catch(err => {
          dispatch({ type: "SINGLE_MODULE_SUBMIT_ERR", err });
        });
    }
  };
};

export const onSubmitHandler = () => {
  let forSave = [];
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "IN_PROGRESS_SAVE_LOADER_MODULES" });
    const { homepageModules, homepageModulesOld } = getState().modules;
    for (let x = 0; x < homepageModules.length; x++) {
      const change = compare(homepageModules[x], homepageModulesOld[x]);
      if (change.length > 0) {
        forSave.push({ id: homepageModules[x].id, changes: change });
      }
    }
    const firestore = getFirestore();

    const promises = [];
    forSave.forEach(changeItem => {
      promises.push(
        firestore
          .collection("homepageModules")
          .doc(changeItem.id)
          .update(changeItem.changes[0])
      );
    });

    Promise.all(promises)
      .then(() => {
        dispatch({ type: "IN_PROGRESS_SAVE_LOADER_MODULES_FINISHED" });
      })
      .catch(err => {
        console.log("ERR", err);
      });
  };
};

export const getModuleInfo = (moduleId, forceLoading) => {
  console.log("modId, force", moduleId, forceLoading);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "SINGLE_MODULE_LOADING_START",
      forceLoading: forceLoading
    });

    const firestore = getFirestore();
    if (moduleId === "about-us") {
      firestore
        .collection("homepageModules")
        .doc(moduleId)
        .get()
        .then(res => {
          dispatch({
            type: "SINGLE_MODULE_LOADING_SUC",
            moduleData: res.data(),
            moduleItemsData: []
          });
        })
        .catch(err => {
          console.log("dostal jsem >> #ERRAF ", err);
          dispatch({ type: "SINGLE_MODULE_LOADING_ERR", err: err });
        });
    } else {
      const promises = [];

      dispatch({
        type: "SINGLE_MODULE_LOADING_START",
        forceLoading: forceLoading
      });

      promises.push(
        firestore
          .collection("homepageModules")
          .doc(moduleId)
          .get()
      );

      const moduleCamelValue =
        moduleId.charAt(0).toUpperCase() + moduleId.slice(1);

      promises.push(
        firestore
          .collection("homepageModules" + moduleCamelValue + "Items")
          .get()
      );

      Promise.all(promises)
        .then(res => {
          const items = res[1].docs.map(doc => {
            const modifedDoc = doc.data();
            modifedDoc.id = doc.id;
            return modifedDoc;
          });
          dispatch({
            type: "SINGLE_MODULE_LOADING_SUC",
            moduleData: res[0].data(),
            moduleItemsData: items
          });
        })
        .catch(err => {
          console.log("ERR", err);
        });
    }
  };
};

export const pushNewItemsForSingleModule = newItemConfig => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "PUSH_NEW_ITEM_TO_MODULE", newItem: newItemConfig });
  };
};

export const deleteModuleItem = (moduleId, itemId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection(
        "homepageModules" +
          moduleId.charAt(0).toUpperCase() +
          moduleId.slice(1) +
          "Items"
      )
      .doc(itemId)
      .delete()
      .then(() => {
        dispatch(getModuleInfo(moduleId, true));
        console.log("Saved");
      });
  };
};

export const deleteModuleNewItem = newItemPosition => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "DELETE_NEW_ITEM_HAN", itemPosition: newItemPosition });
    //console.log("itemsX", itemsX);
    //console.log("position >>", newItemPosition);
  };
};
