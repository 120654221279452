import React from "react";
import HomepageSection from "../HomepageSection";

const HomepageNearby = props => {
  if (!props.moduleNearbyGlobal.isVisible) {
    return null;
  }
  const nearbyItemsRender = props.moduleNearby.map((item, index) => {
    return (
      <div className="near-item" key={"near-item-" + index}>
        <div className="near-item__top">
          <div className="near-item__title">{item.name}</div>
          <div className="near-item__distance">{item.distance} km</div>
        </div>
        <div className="near-item__bottom">
          <div className="near-item__text">{item.perex}</div>
          <a href={item.moreUrl}>Zjistit více</a>
        </div>
      </div>
    );
  });
  return (
    <HomepageSection title={props.moduleNearbyGlobal.name}>
      <div className="near-items">{nearbyItemsRender}</div>
    </HomepageSection>
  );
};

export default HomepageNearby;
