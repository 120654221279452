import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../AdminSpinner/AdminSpinner";
import IconRenderer from "../../IconRenderer/IconRenderer";

//antd
import { Modal as ModalAntd, Button, Icon, message, Card } from "antd";
//antd

import AdminHomepageModuleInputWrapper from "../AdminHomePageModules/AdminHomepageModule/AdminHomepageModuleInputWrapper";
import AdminHomepageModuleWrapper from "../AdminHomePageModules/AdminHomepageModule/AdminHomepageModuleWrapper";

import Modal from "../../Modal/Modal";

import {
  cmsGetImages,
  cmsUploadFiles,
  cmsGetFileDetailInfo,
  cmsGetGalleryModuleGlobal,
  cmsGalleryUpdateGlobalConfig,
  cmsGallerySaveGalleryGlobal,
  cmsGalleryUpdateGalleryPickedFile,
  cmsGallerySaveGalleryPickedFile,
  cmsGallerySetDetailNull,
  clearUploadingFiles,
  deleteFileFromStorage,
  clearDeletingData
} from "../../../store/actions/cmsActions";

const { confirm } = ModalAntd;

class AdminCmsPageGallery extends Component {
  componentDidMount() {
    this.props.cmsGetGalleryModuleGlobal();
    this.props.cmsGetImages();
  }

  addImageOnClickHandler() {
    console.log("so lets upload");
  }

  msgInterval;

  fileDeleteIsActiveMessage = () => {
    if (this.props.cmsGalleryReducer.deletingFileIsActive) {
      this.msgInterval = message.loading("Probíhá odstraňování souboru", 0);
    } else if (this.props.cmsGalleryReducer.deletingFileSuccess) {
      this.props.cmsGallerySetDetailNull();
      setTimeout(this.msgInterval, 0);
      this.msgInterval = message.success("Soubor byl úspěšně vymazán");
      setTimeout(this.msgInterval, 2500);
      this.props.clearDeletingData();
      this.props.cmsGetImages();
    } else if (this.props.cmsGalleryReducer.deletingFileError) {
      this.props.cmsGallerySetDetailNull();
      setTimeout(this.msgInterval, 0);
      this.msgInterval = message.error("Soubor se nepodařilo vymazat");
      setTimeout(this.msgInterval, 2500);
      this.props.clearDeletingData();
      this.props.cmsGetImages();
    }
  };

  showDeleteConfirm = () => {
    const { name, id } = this.props.cmsGalleryReducer.selectedFileDetail;
    console.log("name, id", name, id);
    const deleteFileFromStorage = this.props.deleteFileFromStorage;
    confirm({
      title: `Jste si jistí?`,
      content: `Opravdu si přeje vymazat soubor "${name}"? `,
      okText: "Vymazat",
      okType: "danger",
      cancelText: "Storno",
      onOk() {
        deleteFileFromStorage(name, id);
        console.log("Let's delete this");
      },
      onCancel() {
        console.log("Let's cancel this");
      }
    });
  };

  state = {
    filesForUpload: [],
    isModalActive: false,
    ModalText: "Content of the modal",
    visible: false,
    confirmLoading: false,
    isUploadActive: false,
    okButtonProps: {},
    uploaded: false,
    modalOkText: "Nahrát",
    timerToTurnOff: 8
  };

  //antd

  intervalN;

  realInterval = () => {
    // let timer = 8;
    console.log("timer pop", this.state.timerToTurnOff);
    this.setState({ timerToTurnOff: this.state.timerToTurnOff - 1 });
    // timer = timer - 1;
    if (this.state.timerToTurnOff === 0) {
      this.cleanIntervalForCloseModal();
      this.setState({ timerToTurnOff: 8 });
    }
  };

  doIntervalForCloseModal = () => {
    this.intervalN = setInterval(this.realInterval, 1000);
  };

  cleanIntervalForCloseModal = () => {
    clearInterval(this.intervalN);
    this.setState({ visible: false });
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false
      });
    }, 2000);
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState({
      visible: false
    });
  };

  handleClose = () => {
    console.log("closed no metter what");
    this.cleanIntervalForCloseModal();
    this.props.clearUploadingFiles();
    this.setState({
      filesForUpload: [],
      isUploadActive: false,
      okButtonProps: {},
      uploaded: false,
      modalOkText: "Nahrát",
      timerToTurnOff: 8
    });
    this.props.cmsGetImages();
  };
  //antd

  onChangeFilesToUploadHandler(e) {
    const filesLength = e.target.files.length;
    const filesForUpload = [];

    for (let x = 0; x < filesLength; x++) {
      filesForUpload.push(e.target.files[x]);
    }

    this.setState({ filesForUpload: filesForUpload });
    console.log("FPU", filesForUpload);
  }

  onClickUploadFilesHandler() {
    console.log("files for upload...", this.state.filesForUpload);
    this.props.cmsUploadFiles(this.state.filesForUpload);
    this.setState({
      confirmLoading: true,
      isUploadActive: true
    });
  }

  setModalActive() {
    this.setState({ isModalActive: true });
  }

  setModalInactive() {
    this.setState({ isModalActive: false, filesForUpload: [] });
    this.props.cmsGetImages();
  }

  viewFileInfo(id) {
    this.props.cmsGetFileDetailInfo(id);
  }

  returnTableRow(placeholder, value, cssName) {
    return (
      <tr>
        <td>{placeholder}</td>
        <td className={cssName ? null : null}>
          <span className="table-short">{value}</span>
        </td>
      </tr>
    );
  }

  renderAsideFileInfoBox() {
    let renderFileDetail = null;
    if (
      this.props.cmsGalleryReducer.selectedFileDetail !== null &&
      this.props.cmsGalleryReducer.loadingSelectedFileDetail === false
    ) {
      const item = this.props.cmsGalleryReducer.selectedFileDetail;
      renderFileDetail = (
        <div>
          <a href={item.url} target="_blank">
            <img src={item.url} alt="" />
          </a>
          <div className="hp-module-detail">
            <div className="module-row">
              <AdminHomepageModuleInputWrapper label="Název">
                <input
                  autoComplete="off"
                  type="text"
                  id="name"
                  value={item.name}
                  disabled
                />
              </AdminHomepageModuleInputWrapper>
              <AdminHomepageModuleInputWrapper
                label="Zobrazovat na hlavní stránce"
                css={"checkbox-admin " + (item.showOnHomepage ? "true" : null)}
                for={"showOnHomepage-" + item.id}
                text={item.showOnHomepage ? "Ano" : "Ne"}
              >
                <input
                  type="checkbox"
                  checked={item.showOnHomepage}
                  onChange={e => this.updateGalleryPickedFile(e)}
                  id={"showOnHomepage-" + item.id}
                />
              </AdminHomepageModuleInputWrapper>
              <AdminHomepageModuleInputWrapper label="Typ">
                {item.type}
              </AdminHomepageModuleInputWrapper>
              <AdminHomepageModuleInputWrapper
                label="Zobrazovat"
                css={"checkbox-admin " + (item.isVisible ? "true" : null)}
                for={"isVisible-" + item.id}
                text={item.isVisible ? "Ano" : "Ne"}
                label="Zobrazovat"
              >
                <input
                  type="checkbox"
                  checked={item.isVisible}
                  onChange={e => this.updateGalleryPickedFile(e)}
                  id={"isVisible-" + item.id}
                />
              </AdminHomepageModuleInputWrapper>
              <AdminHomepageModuleInputWrapper label="Velikost">
                {Math.ceil(item.size / 1024) + "kB"}
              </AdminHomepageModuleInputWrapper>
              <AdminHomepageModuleInputWrapper label="Popis">
                <textarea
                  className="info-desc"
                  defaultValue={item.description}
                  onChange={e => this.updateGalleryPickedFile(e)}
                  id="description"
                />
              </AdminHomepageModuleInputWrapper>
              <AdminHomepageModuleInputWrapper label="Krátky popis">
                <textarea
                  className="info-desc"
                  defaultValue={item.alt}
                  id="alt"
                  onChange={e => this.updateGalleryPickedFile(e)}
                />
              </AdminHomepageModuleInputWrapper>
              <AdminHomepageModuleInputWrapper label="Pořadí">
                <input
                  type="number"
                  value={item.order}
                  id="order"
                  onChange={e => this.updateGalleryPickedFile(e)}
                />
              </AdminHomepageModuleInputWrapper>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem"
            }}
          >
            <Button
              size="large"
              className="btn-save"
              onClick={() =>
                this.props.cmsGallerySaveGalleryPickedFile(item.id)
              }
            >
              Uložit
            </Button>
            <Button
              className="btn-red"
              size="large"
              onClick={() => this.showDeleteConfirm()}
            >
              <Icon type="delete" /> Vymazat
            </Button>
            <Button
              size="large"
              className="btn-red"
              onClick={() => this.props.cmsGallerySetDetailNull()}
            >
              Zavřít
            </Button>
          </div>
        </div>
      );
    } else if (this.props.cmsGalleryReducer.loadingSelectedFileDetail) {
      renderFileDetail = (
        <div className="loading">
          <Spinner />
        </div>
      );
    } else {
      renderFileDetail = <div className="c-f">Vyberte obrázek</div>;
    }
    return renderFileDetail;
  }

  renderCmsHeaderSection() {
    const {
      name,
      perex,
      isVisible
    } = this.props.cmsGalleryReducer.galleryModuleGlobal;
    return (
      <div>
        <AdminHomepageModuleWrapper
          label={name}
          heading="Nastavení CMS stránky"
          backButtonIsDisabled
        >
          <AdminHomepageModuleInputWrapper label="Název">
            <input
              autoComplete="off"
              type="text"
              id="name"
              value={name}
              onChange={e => this.updateGalleryGlobal(e)}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper
            label="Zobrazovat"
            css={"checkbox-admin " + (isVisible ? "true" : null)}
            for="isVisible"
            text={isVisible ? "Ano" : "Ne"}
            label="Zobrazovat"
          >
            <input
              autoComplete="off"
              id="isVisible"
              onChange={e => this.updateGalleryGlobal(e)}
              type="checkbox"
              checked={isVisible}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Perex">
            <textarea
              id="perex"
              onChange={e => this.updateGalleryGlobal(e)}
              defaultValue={perex}
            />
          </AdminHomepageModuleInputWrapper>
          <Button
            type="submit"
            className="btn btn-save"
            onClick={() => this.props.cmsGallerySaveGalleryGlobal()}
          >
            Uložit
          </Button>
        </AdminHomepageModuleWrapper>
      </div>
    );
  }

  updateGalleryGlobal(e) {
    if (e.target.type === "checkbox") {
      this.props.cmsGalleryUpdateGlobalConfig(e.target.id, e.target.checked);
    } else {
      this.props.cmsGalleryUpdateGlobalConfig(e.target.id, e.target.value);
    }
  }

  updateGalleryPickedFile(e) {
    if (e.target.type === "checkbox") {
      this.props.cmsGalleryUpdateGalleryPickedFile(
        e.target.id.split("-")[0],
        e.target.checked
      );
    } else {
      this.props.cmsGalleryUpdateGalleryPickedFile(
        e.target.id.split("-")[0],
        e.target.value
      );
    }
  }

  render() {
    // console.log(
    //   "find me active ID",
    //   this.props.cmsGalleryReducer.selectedFileDetail
    // );
    this.fileDeleteIsActiveMessage();
    if (this.state.isUploadActive) {
      console.log(
        `ToUpload:[${this.state.filesForUpload.length}] , Uploading:[${this.props.cmsGalleryReducer.uploadingFiles.length}]`
      );
      if (
        this.state.filesForUpload.length ===
        this.props.cmsGalleryReducer.uploadingFiles.length
      ) {
        this.doIntervalForCloseModal();
        // this.props.clearUploadingFiles();
        this.setState({
          // filesForUpload: [],
          confirmLoading: false,
          okButtonProps: {
            icon: "check",
            disabled: true,
            style: { background: "#6bb37e", color: "#fff" }
          },
          uploaded: true,
          isUploadActive: false,
          modalOkText: "Nahráno"
        });
      }
    }
    const {
      uploadedFilesInBucket,
      loadingSelectedFileDetail,
      selectedFileDetail
    } = this.props.cmsGalleryReducer;

    const renderImgs = uploadedFilesInBucket.map(item => {
      // const activePic = this.props.cmsGalleryReducer.selectedFileDetail
      //   ? this.props.cmsGalleryReducer.selectedFileDetail.id
      //   : {};
      if (this.props.cmsGalleryReducer.selectedFileDetail) {
        const { Meta } = Card;
        let classNames = "";
        if (this.props.cmsGalleryReducer.selectedFileDetail.id === item.id) {
          classNames = "active ";
        }
        if (!item.isVisible) {
          classNames = classNames + "disabled";
        }
        return (
          <Card
            key={`img-mini-${item.id}`}
            hoverable
            className={classNames}
            onClick={() => this.viewFileInfo(item.id)}
            cover={
              <div className="pic-wrapper">
                <span className="highligted">
                  {item.showOnHomepage && (
                    <IconRenderer iconName="star" theme="filled"></IconRenderer>
                  )}
                </span>
                <img src={item.url} />
              </div>
            }
          >
            <Meta description={item.name} />
          </Card>
          // <div
          // className={
          //   this.props.cmsGalleryReducer.selectedFileDetail.id === item.id
          //     ? "img-wrapper active"
          //     : "img-wrapper"
          // }
          //   onClick={() => this.viewFileInfo(item.id)}
          //   key={`img-mini-${item.id}`}
          // >
          //   <img src={item.url} />
          //   <div className={item.isVisible ? "img-info" : "img-info disabled"}>
          //     <span className="img-name">{item.name}</span>
          //     <span className="img-size">
          //       {Math.ceil(item.size / 1024 /*/ 1024*/)} kB
          //     </span>
          //   </div>
          // </div>
        );
      } else {
        const { Meta } = Card;
        return (
          <Card
            key={`img-mini-${item.id}`}
            hoverable
            className={!item.isVisible && "disabled"}
            onClick={() => this.viewFileInfo(item.id)}
            cover={
              <div className="pic-wrapper">
                <span className="highligted">
                  {item.showOnHomepage && (
                    <IconRenderer iconName="star" theme="filled"></IconRenderer>
                  )}
                </span>
                <img src={item.url} />
              </div>
            }
          >
            <Meta description={item.name} />
          </Card>
          // <div
          //   className="img-wrapper"
          //   onClick={() => this.viewFileInfo(item.id)}
          //   key={`img-mini-${item.id}`}
          // >
          //   <img src={item.url} />
          //   <div className={item.isVisible ? "img-info" : "img-info disabled"}>
          //     <span className="img-name">{item.name}</span>
          //     <span className="img-size">
          //       {Math.ceil(item.size / 1024 /*/ 1024*/)} kB
          //     </span>
          //   </div>
          // </div>
        );
      }
    });

    const renderUploadedImages = () => {
      const returnDiv = this.props.cmsGalleryReducer.uploadingFiles.map(
        file => {
          console.log("URL !!!!!!", file.url);
          if (file.url === undefined) {
            return (
              <div
                style={{
                  background: "red",
                  width: "100px",
                  height: "100px",
                  fontSize: "9px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                Načítání...
              </div>
            );
          } else {
            return <img src={file.url} width="100px" height="100px" />;
          }
        }
      );
      return <div className="uploaded-images-container">{returnDiv}</div>;
    };

    const modalBody = (
      <div>
        <div className="modal-body-inner">
          <span className="guide">Vyberte jeden, či více obrázků</span>
          {!this.state.uploaded && (
            <input
              type="file"
              name="filesToUpload"
              id="filesToUpload"
              multiple={true}
              onChange={e => this.onChangeFilesToUploadHandler(e)}
              value={this.state.filesForUpload.length === 0 ? "" : null}
              accept=".jpg,.jpeg,.png,.gif"
            />
          )}
          {this.state.uploaded && (
            <p>
              Soubory byly úspěšně nahrány, okno zavřete, nebo se za{" "}
              {this.state.timerToTurnOff} sekund zavře samo.
            </p>
          )}
          {this.state.uploaded ? renderUploadedImages() : null}

          {/*<div className="footer-buttons">
            {this.props.cmsGalleryReducer.uploadingFiles.length > 0 &&
            this.state.filesForUpload.length > 0 ? null : (
              <button onClick={() => this.onClickUploadFilesHandler()}>
                Nahrát
              </button>
            )}
            <button onClick={() => this.setModalInactive()}>Zrušit</button>
          </div>*/}
        </div>
      </div>
    );

    const { visible, confirmLoading, ModalText } = this.state;

    return (
      <div className="cms-page-admin">
        <Modal isVisible={this.state.isModalActive}>{modalBody}</Modal>
        {this.props.cmsGalleryReducer.galleryModuleGlobalIsLoading
          ? "Loading"
          : this.renderCmsHeaderSection()}
        <div className="gallery-buttons">
          <div>
            <Button type="primary" onClick={this.showModal}>
              Přidat obrázky
            </Button>
            <ModalAntd
              title="Přidat obrázky"
              visible={visible}
              onOk={() => this.onClickUploadFilesHandler()}
              okText={confirmLoading ? "Nahrávání" : this.state.modalOkText}
              cancelText="Zavřít"
              cancelButtonProps={confirmLoading && { disabled: true }}
              confirmLoading={confirmLoading}
              onCancel={this.handleCancel}
              okButtonProps={this.state.okButtonProps}
              afterClose={this.handleClose}
            >
              <div>{modalBody}</div>
            </ModalAntd>
          </div>
        </div>
        <div className="gallery-wrapper row">
          <div
            className={
              this.props.cmsGalleryReducer.selectedFileDetail == null
                ? "col-md-8 col-sm-12 gallery-container"
                : "col-md-8 col-sm-12 gallery-container"
            }
          >
            {!this.props.loadingSelectedFileDetail && renderImgs}
          </div>
          <div className="col-md-4 info-card">
            <Card>{this.renderAsideFileInfoBox()}</Card>
          </div>
          {/*<div
            className={
              this.props.cmsGalleryReducer.selectedFileDetail == null
                ? "gallery-info w-0 o-0"
                : "gallery-info i-1"
            }
          >
            {this.renderAsideFileInfoBox()}
          </div>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { cmsGalleryReducer: state.cmsGalleryReducer };
};

const mapDispatchToProps = dispatch => {
  return {
    cmsGetImages: () => dispatch(cmsGetImages()),
    cmsUploadFiles: filesForUpload => dispatch(cmsUploadFiles(filesForUpload)),
    cmsGetFileDetailInfo: id => dispatch(cmsGetFileDetailInfo(id)),
    cmsGetGalleryModuleGlobal: () => dispatch(cmsGetGalleryModuleGlobal()),
    cmsGalleryUpdateGlobalConfig: (id, value) =>
      dispatch(cmsGalleryUpdateGlobalConfig(id, value)),
    cmsGallerySaveGalleryGlobal: () => dispatch(cmsGallerySaveGalleryGlobal()),
    cmsGalleryUpdateGalleryPickedFile: (id, value) =>
      dispatch(cmsGalleryUpdateGalleryPickedFile(id, value)),
    cmsGallerySaveGalleryPickedFile: id =>
      dispatch(cmsGallerySaveGalleryPickedFile(id)),
    cmsGallerySetDetailNull: () => dispatch(cmsGallerySetDetailNull()),
    clearUploadingFiles: () => dispatch(clearUploadingFiles()),
    deleteFileFromStorage: (name, id) =>
      dispatch(deleteFileFromStorage(name, id)),
    clearDeletingData: () => dispatch(clearDeletingData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCmsPageGallery);
