import React from "react";
import AdminHomepageModuleWrapper from "./AdminHomepageModuleWrapper.js";
import AdminHomepageModuleInputWrapper from "./AdminHomepageModuleInputWrapper";
import AdminSpinner from "../../../AdminSpinner/AdminSpinner";

const AdminHomepageModuleContacts = props => {
  const { isVisible, name, order, mapSrcUrl } = props.moduleData;
  const items = props.items;
  const onChangeHandler = props.onChangeHandler;
  const onChangeHandlerForItems = props.onChangeHandlerForItems;
  const onSubmitHandler = props.onSubmitHandler;

  const singleModuleItemsDataAdded = props.singleModuleItemsDataAdded;
  const onDeleteHandler = props.onDeleteHandler;
  const deleteModuleNewItem = props.deleteModuleNewItem;
  const onChangeHandlerForNewItems = props.onChangeHandlerForNewItems;
  const newItemConfig = {
    icon: "phone",
    order: 0,
    value: ""
  };

  const trashIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
    >
      <path d="M 10 2 L 9 3 L 5 3 C 4.448 3 4 3.448 4 4 C 4 4.552 4.448 5 5 5 L 7 5 L 17 5 L 19 5 C 19.552 5 20 4.552 20 4 C 20 3.448 19.552 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.105 5.895 22 7 22 L 17 22 C 18.105 22 19 21.105 19 20 L 19 7 L 5 7 z" />
    </svg>
  );
  let newItemsRender = null;
  if (singleModuleItemsDataAdded.length > 0) {
    newItemsRender = singleModuleItemsDataAdded.map((item, index) => {
      return (
        <div className="sub-item new threeway" key={"new-item_input" + index}>
          <div
            className="delete"
            onClick={itemPosition => deleteModuleNewItem(index)}
          >
            {trashIcon}
          </div>
          <div className="icon-preview">
            <i className={"fas fa-" + item.icon} />
          </div>
          <select
            id="icon"
            onChange={e => onChangeHandlerForNewItems(e, index)}
            value={item.icon}
            autocomplete="off"
          >
            <option value="phone">Telefon</option>
            <option value="envelope">Obálka</option>
            <option value="map">Mapa</option>
          </select>
          <input
            autocomplete="off"
            type="text"
            id="value"
            autocomplete="off"
            onChange={e => onChangeHandlerForNewItems(e, index)}
            value={item.value}
          />
          <input
            onChange={e => onChangeHandlerForNewItems(e, index)}
            type="number"
            id="order"
            value={item.order}
          />
        </div>
      );
    });
  }

  const itemsRender = items.map((item, index) => {
    return (
      <div className="sub-item threeway" key={"new-item_input" + index}>
        <div className="delete" onClick={itemId => onDeleteHandler(item.id)}>
          {trashIcon}
        </div>
        <div className="icon-preview">
          <i className={"fas fa-" + item.icon} />
        </div>
        <select
          id="icon"
          onChange={e => onChangeHandlerForItems(e, index)}
          value={item.icon}
        >
          <option value="phone">Telefon</option>
          <option value="envelope">Obálka</option>
          <option value="map">Mapa</option>
        </select>
        <input
          type="text"
          id="value"
          autocomplete="off"
          onChange={e => onChangeHandlerForItems(e, index)}
          value={item.value}
        />
        <input
          onChange={e => onChangeHandlerForItems(e, index)}
          type="number"
          id="order"
          value={item.order}
        />
      </div>
    );
  });
  return (
    <AdminHomepageModuleWrapper label={name}>
      <form onSubmit={onSubmitHandler}>
        <AdminHomepageModuleInputWrapper label="Název">
          <input
            autocomplete="off"
            onChange={onChangeHandler}
            id="name"
            type="text"
            value={name}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper
          css={"checkbox-admin " + (isVisible ? "true" : null)}
          label="Zobrazovat"
          for="isVisible"
          text={isVisible ? "Ano" : "Ne"}
        >
          <input
            id="isVisible"
            onChange={onChangeHandler}
            type="checkbox"
            checked={isVisible}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Pořadí">
          <input
            id="order"
            onChange={onChangeHandler}
            type="number"
            value={order}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="URL google mapy">
          <input
            autocomplete="off"
            id="mapSrcUrl"
            onChange={onChangeHandler}
            type="text"
            value={mapSrcUrl}
          />
        </AdminHomepageModuleInputWrapper>
        <div className="sub-heading">Položky</div>
        {itemsRender}
        {newItemsRender}
        <div className="new-items">
          <button
            type="button"
            className="add-new-row"
            onClick={() => props.addNewItemHandler(newItemConfig)}
          >
            Přidat další položku
          </button>
        </div>
        <button type="submit" className="btn btn-save">
          {props.isSubmiting ? <AdminSpinner text=" " /> : null}
          Uložit
        </button>
      </form>
    </AdminHomepageModuleWrapper>
  );
};

export default AdminHomepageModuleContacts;
