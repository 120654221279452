import React from "react";
import AdminHomepageModuleWrapper from "./AdminHomepageModuleWrapper.js";
import AdminHomepageModuleInputWrapper from "./AdminHomepageModuleInputWrapper";
import AdminSpinner from "../../../AdminSpinner/AdminSpinner";

const AdminHomepageModulePrices = props => {
  console.log("prps", props);
  const { isVisible, name, order, mapSrcUrl } = props.moduleData;
  const items = props.items;
  const onChangeHandler = props.onChangeHandler;
  const onChangeHandlerForItems = props.onChangeHandlerForItems;
  const onChangeHandlerForNewItems = props.onChangeHandlerForNewItems;
  const onSubmitHandler = props.onSubmitHandler;
  const onDeleteHandler = props.onDeleteHandler;
  const deleteModuleNewItem = props.deleteModuleNewItem;
  const newItemConfig = {
    name: "",
    order: 0,
    price: 0,
    priceBefore: 0,
    isMain: false
  };
  const singleModuleItemsDataAdded = props.singleModuleItemsDataAdded;
  //console.log(singleModuleItemsDataAdded, singleModuleItemsDataAdded.length);
  const trashIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
    >
      <path d="M 10 2 L 9 3 L 5 3 C 4.448 3 4 3.448 4 4 C 4 4.552 4.448 5 5 5 L 7 5 L 17 5 L 19 5 C 19.552 5 20 4.552 20 4 C 20 3.448 19.552 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.105 5.895 22 7 22 L 17 22 C 18.105 22 19 21.105 19 20 L 19 7 L 5 7 z" />
    </svg>
  );
  let newItemsRender = null;
  if (singleModuleItemsDataAdded.length > 0) {
    newItemsRender = singleModuleItemsDataAdded.map((item, index) => {
      return (
        <div className="sub-item new" key={"new-item_input" + index}>
          <div
            className="delete"
            onClick={itemPosition => deleteModuleNewItem(index)}
          >
            {trashIcon}
          </div>
          <AdminHomepageModuleInputWrapper label="Text">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="name"
              type="text"
              autoComplete="off"
              value={item.name}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Pořadí">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="order"
              type="number"
              value={item.order}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Cena (Kč)">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="price"
              type="number"
              value={item.price}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper
            onChange={e => onChangeHandlerForNewItems(e, index)}
            id="priceBefore"
            label="Cena před slevou (Kč)"
          >
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="priceBefore"
              type="number"
              value={item.priceBefore}
            />
          </AdminHomepageModuleInputWrapper>
          <AdminHomepageModuleInputWrapper label="Zvýrazněná nabídka">
            <input
              onChange={e => onChangeHandlerForNewItems(e, index)}
              id="isMain"
              type="checkbox"
              checked={item.isMain}
            />
          </AdminHomepageModuleInputWrapper>
        </div>
      );
    });
  }
  //console.log(props);
  const itemsRender = items.map((item, index) => {
    //console.log("aaa", item);
    return (
      <div className="sub-item" key={"item_input" + index}>
        <div className="delete" onClick={itemId => onDeleteHandler(item.id)}>
          {trashIcon}
        </div>
        <AdminHomepageModuleInputWrapper label="Text">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="name"
            type="text"
            autoComplete="off"
            value={item.name}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Pořadí">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="order"
            type="number"
            value={item.order}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Cena (Kč)">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="price"
            type="number"
            value={item.price}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper
          onChange={e => onChangeHandlerForItems(e, index)}
          id="priceBefore"
          label="Cena před slevou (Kč)"
        >
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="priceBefore"
            type="number"
            value={item.priceBefore}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Zvýrazněná nabídka">
          <input
            onChange={e => onChangeHandlerForItems(e, index)}
            id="isMain"
            type="checkbox"
            checked={item.isMain}
          />
        </AdminHomepageModuleInputWrapper>
      </div>
    );
  });
  return (
    <AdminHomepageModuleWrapper label={name}>
      <form onSubmit={onSubmitHandler}>
        <AdminHomepageModuleInputWrapper label="Název">
          <input
            id="name"
            autoComplete="off"
            onChange={onChangeHandler}
            type="text"
            value={name}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper
          css={"checkbox-admin " + (isVisible ? "true" : null)}
          label="Zobrazovat"
          for="isVisible"
          text={isVisible ? "Ano" : "Ne"}
          label="Zobrazovat"
        >
          <input
            id="isVisible"
            onChange={onChangeHandler}
            type="checkbox"
            checked={isVisible}
          />
        </AdminHomepageModuleInputWrapper>
        <AdminHomepageModuleInputWrapper label="Pořadí">
          <input
            id="order"
            onChange={onChangeHandler}
            type="number"
            value={order}
          />
        </AdminHomepageModuleInputWrapper>
        <div className="sub-heading">Položky</div>
        {itemsRender}
        {newItemsRender}
        <div className="new-items">
          <button
            type="button"
            className="add-new-row"
            onClick={() => props.addNewItemHandler(newItemConfig)}
          >
            Přidat další položku
          </button>
        </div>
        <button type="submit" className="btn btn-save">
          {props.isSubmiting ? <AdminSpinner text=" " /> : null}
          Uložit
        </button>
      </form>
    </AdminHomepageModuleWrapper>
  );
};

export default AdminHomepageModulePrices;
