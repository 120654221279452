import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";

import {
  fetchNotifications,
  fetchNotification
} from "../../../store/actions/notificationsActions";

class AdminNotificationsDetail extends Component {
  componentDidMount() {
    this.props.fetchNotification();
  }
  render() {
    const { notifications } = this.props.notifications;
    const wannabeGrid = notifications.map(item => {
      return (
        <div>
          <span>{item.name}</span>
          <span>{moment(item.from.toDate()).format("D/M YYYY")}</span> {" - "}
          <span>{moment(item.to.toDate()).format("D/M YYYY")}</span>
          <a href={"/admin/notification/" + item.id}>Detail</a>
        </div>
      );
    });
    return (
      <div>
        Admin notification detail
        <br /> {wannabeGrid}{" "}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications
  };
};

const mapDisptachToProps = dispatch => {
  return {
    fetchNotification: id => dispatch(fetchNotification(id))
  };
};

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(AdminNotificationsDetail);
