import firebase from "firebase/app";
import "firebase/functions";
import React, { Component } from "react";
import { connect } from "react-redux";
import IconRenderer, {
  returnMapOfIcons
} from "../../IconRenderer/IconRenderer";
import { Card, Icon, Input, Select, Modal as AntdModal, Button } from "antd";

import Modal from "../../Modal/Modal";

import { getFirestore } from "redux-firestore";

import {
  cmsInfoGetGlobalConfig,
  cmsInfoUpdateGlobalConfig,
  cmsInfoSaveGlobalConfig,
  cmsInfoGetModuleItems,
  cmsInfoModulePushNewItem,
  cmsInfoUpdateExistingItem,
  cmsInfoUpdateNewItem,
  cmsInfoDeleteNewItem,
  cmsSaveInfoContent,
  cmsAddDeletedItem,
  cmsInfoSaveSingleItem
} from "../../../store/actions/cmsActions";

class AdminCmsPageInfo extends Component {
  componentDidMount() {
    this.props.cmsInfoGetGlobalConfig();
    this.props.cmsInfoGetModuleItems();
  }

  state = {
    isModalActive: false,
    modalBody: null,
    editMode: {
      enabled: false,
      id: null,
      icon: null,
      name: null,
      description: null
    },
    antdModalText: "Modal text",
    antdModalVisible: false,
    antdModalConfirmLoading: false,
    newItem: {
      description: "",
      icon: "question",
      name: "",
      order: 0
    },
    modalContent: <div>content...</div>
  };

  showDeleteConfirm = (id, name) => {
    // console.log("pls:D");
    const getUserByUid = firebase.functions().httpsCallable("getUserByUid");
    getUserByUid("hoA2YM9x1gcX4WOnmuXmh30Z7fh2").then(res => {
      // console.log("resOfShiet", res);
    });
    // const deleteFileFromStorage = this.props.deleteFileFromStorage;
    const { confirm } = AntdModal;

    const updateInfo = () => {
      this.props.cmsInfoGetModuleItems();
    };

    confirm({
      title: `Jste si jistí?`,
      content: `Opravdu si přeje vymazat položku "${name}"? `,
      okText: "Vymazat",
      okType: "danger",
      cancelText: "Storno",
      onOk() {
        const firestore = getFirestore();
        firestore
          .collection("cmsContentInfo")
          .doc(id)
          .delete()
          .then(res => {
            updateInfo();
            this.setState({
              antdModalConfirmLoading: false,
              antdModalVisible: false
            });
            //Update all
          })
          .catch(err => console.log("err", err));
      },
      onCancel() {}
    });
  };

  antdModalOpen = () => {
    this.setState({ antdModalVisible: true });
  };

  antdModalCloseHandler = () => {
    this.setState({
      antdModalVisible: false
    });
  };

  asnycFunc = () => {
    // this.setState({ antdModalConfirmLoading: false });
    const finalItemToAdd = { ...this.state.newItem };
    finalItemToAdd.createdAt = new Date();
    const firestore = getFirestore();
    firestore
      .collection("cmsContentInfo")
      .add(finalItemToAdd)
      .then(res => {
        this.props.cmsInfoGetModuleItems();
        this.setState({
          antdModalConfirmLoading: false,
          antdModalVisible: false
        });
        //Update all
      });
    // firestore
    //   .collection("cmsPages")
    //   .doc("info")
    //   .get()
    //   .then(res => {
    //     console.log("res", res);
    //     this.setState({
    //       antdModalConfirmLoading: false,
    //       antdModalVisible: false
    //     });
    //   });
  };

  antdModalOkFunction = () => {
    const nullNewItem = {
      description: "",
      icon: "question",
      name: "",
      order: 0
    };
    this.setState({ antdModalConfirmLoading: true, newItem: nullNewItem });
    this.asnycFunc();

    // this.setState({
    //   antdModalText: "The modal will be closed after two seconds",
    //   antdModalConfirmLoading: true
    // });
    // setTimeout(() => {
    //   this.setState({
    //     antdModalVisible: false,
    //     antdModalConfirmLoading: false
    //   });
    // }, 2000);
  };

  createNewItem = () => {
    this.antdModalOpen();
  };

  setEditMode = item => {
    const { id, icon, name, description } = item;
    this.setState({ editMode: { enabled: true, id, icon, name, description } });
  };

  saveItem = () => {
    const { enabled, ...rest } = this.state.editMode;
    this.props.cmsInfoSaveSingleItem(rest);
    this.setState({ editMode: { enabled: false, id: null } });
  };

  cancelEdit = () => {
    this.setState({ editMode: { enabled: false, id: null } });
  };

  setModalActive(modalBody) {
    this.setState({ isModalActive: true, modalBody });
  }

  setModalInactive() {
    this.setState({ isModalActive: false, modalBody: null });
  }

  updateValuesInGlobalConfig(e) {
    if (e.target.type === "checkbox") {
      this.props.cmsInfoUpdateGlobalConfig(e.target.id, e.target.checked);
    } else {
      this.props.cmsInfoUpdateGlobalConfig(e.target.id, e.target.value);
    }
  }

  updateDropdownItem = (e, id, idOfItem) => {
    const updatedEditMode = {
      ...this.state.editMode,
      [id]: e
    };
    this.setState({ editMode: updatedEditMode });
  };

  updateDropdownItemNewItem = (e, id, idOfItem) => {
    const newItemUpdated = { ...this.state.newItem };
    newItemUpdated.icon = e;
    this.setState({ newItem: newItemUpdated });
  };

  updateValueInNewItem = (e, idOfItem) => {
    const newItemUpdated = { ...this.state.newItem };
    newItemUpdated[idOfItem] = e.target.value;
    this.setState({ newItem: newItemUpdated });
  };

  updateSingleItem = (e, idOfItem) => {
    const updatedEditMode = {
      ...this.state.editMode,
      [e.target.id]: e.target.value
    };
    this.setState({ editMode: updatedEditMode });
  };

  updateValueInExistingItems(e, idOfItem) {
    if (e.target.type === "checkbox") {
      this.props.cmsInfoUpdateExistingItem(
        e.target.id,
        idOfItem,
        e.target.checked
      );
    } else {
      this.props.cmsInfoUpdateExistingItem(
        e.target.id,
        idOfItem,
        e.target.value
      );
    }
  }

  updateValueInNewAddedItems(e, position) {
    if (e.target.type === "checkbox") {
      this.props.cmsInfoUpdateNewItem(position, e.target.id, e.target.checked);
    } else {
      this.props.cmsInfoUpdateNewItem(position, e.target.id, e.target.value);
    }
  }

  deleteHandlerFromModal(positionX) {
    this.props.cmsInfoDeleteNewItem(positionX);
  }

  deleteNewItem(positionX) {
    // const modalBody = (
    //   <div>
    //     <p>Opravdu si přejte položku vymazat?</p>
    //     <button onClick={position => this.deleteHandlerFromModal(positionX)}>
    //       Ano
    //     </button>
    //     <button onClick={() => this.setModalInactive()}>Ne </button>
    //   </div>
    // );
    // this.setModalActive(modalBody);
    //TODO setModalActive ?? nvm proč ale ok :D
    this.deleteHandlerFromModal(positionX);
  }

  render() {
    const {
      cmsInfoItems,
      moduleGlobalConfig,
      moduleGlobalConfigIsLoading,
      cmsInfoItemsIsLoading,
      cmsInfoItemsNew
    } = this.props.cmsInfoReducer;

    const renderCmsCongifSection = () => {
      if (!moduleGlobalConfigIsLoading) {
        const { TextArea } = Input;
        const getIconForGlobalSaveButton = () => {
          if (this.props.cmsInfoReducer.savingGlobalConfig) {
            return "loading";
          } else if (this.props.cmsInfoReducer.savedGlobalConfig) {
            return "check";
          } else {
            return null;
          }
        };
        return (
          <div className="global-config">
            <Input
              type="text"
              placeholder="Nadpis"
              value={moduleGlobalConfig.name}
              id="name"
              onChange={e => this.updateValuesInGlobalConfig(e)}
            />
            <TextArea
              value={moduleGlobalConfig.perex}
              id="perex"
              placeholder="Detailnější informace"
              onChange={e => this.updateValuesInGlobalConfig(e)}
            />
            {/*            <input
              type="text"
              value={moduleGlobalConfig.name}
              id="name"
              onChange={e => this.updateValuesInGlobalConfig(e)}
            />
            <textarea
              defaultValue={moduleGlobalConfig.perex}
              id="perex"
              onChange={e => this.updateValuesInGlobalConfig(e)}
/>*/}
            <Button
              icon={getIconForGlobalSaveButton()}
              className="btn-save"
              onClick={() => this.props.cmsInfoSaveGlobalConfig()}
            >
              Uložit
            </Button>
          </div>
        );
      } else {
        return <div>Loading...</div>;
      }
    };

    const renderCmsItemsSection = () => {
      if (!cmsInfoItemsIsLoading) {
        const allItems = cmsInfoItems.map(item => {
          let show = true;
          this.props.cmsInfoReducer.cmsInfoDeletedItems.forEach(id => {
            if (id == item.id) {
              show = false;
            }
          });

          if (!show) {
            return null;
          }

          const { Meta } = Card;

          const editEnabled = this.state.editMode.enabled;
          const editId = this.state.editMode.id;

          const returnEditSaveButton = () => {
            return editEnabled && editId === item.id ? (
              <Icon
                style={{ color: "#2d8949" }}
                onClick={() => this.saveItem()}
                type="check"
              />
            ) : (
              <Icon
                style={{ color: "#1890ff" }}
                onClick={() => this.setEditMode(item)}
                type="edit"
              />
            );
          };

          const returnDeleteCancelButton = () => {
            return editEnabled && editId === item.id ? (
              <Icon
                style={{ color: "#ad4848" }}
                onClick={() => this.cancelEdit()}
                type="close"
              />
            ) : (
              <Icon
                style={{ color: "#ad4848" }}
                // onClick={id => this.props.cmsAddDeletedItem(item.id)}
                type="delete"
                onClick={(id, name) =>
                  this.showDeleteConfirm(item.id, item.name)
                }
              />
            );
          };

          const { TextArea } = Input;
          const { Option } = Select;

          const editModeEnabled = this.state.editMode.enabled;
          const editModeId = this.state.editMode.id;
          const editModeIcon = this.state.editMode.icon;
          const editModeName = this.state.editMode.name;
          const editModeDescription = this.state.editMode.description;

          const mapOfIcons = returnMapOfIcons();
          const optionsForDropDown = mapOfIcons.map(item => {
            return (
              <Option value={item} key={item}>
                <IconRenderer iconName={item} />
              </Option>
            );
          });

          const tryIcoDropdown = (
            <Select
              style={{ width: 70 }}
              placeholder="Select a person"
              optionFilterProp="children"
              value={editModeIcon}
              id="icon"
              onChange={(e, id, idOfItem) =>
                this.updateDropdownItem(e, "icon", item.id)
              }
            >
              {optionsForDropDown}
            </Select>
          );

          let isLoading = false;

          if (
            this.props.cmsInfoReducer.isFatchingNewItem &&
            this.props.cmsInfoReducer.isFatchingNewItem === item.id
          ) {
            isLoading = true;
          }

          return (
            <Card
              key={"cms-item-" + item.id}
              style={{ width: 300 }}
              actions={[returnDeleteCancelButton(), returnEditSaveButton()]}
            >
              <div
                className={isLoading ? "loading-div" : "loading-div disabled"}
              >
                <Icon type="loading" />
              </div>
              <Meta
                avatar={
                  editEnabled && editId === item.id ? (
                    tryIcoDropdown
                  ) : (
                    <IconRenderer iconName={item.icon} />
                  )
                }
                title={
                  editEnabled && editId === item.id ? (
                    <Input
                      value={editModeName}
                      id="name"
                      onChange={(e, id) => this.updateSingleItem(e, item.id)}
                    />
                  ) : (
                    item.name
                  )
                }
                description={
                  editEnabled && editId === item.id ? (
                    <TextArea
                      id="description"
                      onChange={(e, id) => this.updateSingleItem(e, item.id)}
                      value={editModeDescription}
                      autosize={{ minRows: 2, maxRows: 6 }}
                    />
                  ) : (
                    item.description
                  )
                }
              />
            </Card>
          );
        });

        const renderNewItems = (
          <Card
            style={{ width: 300 }}
            className="add-new-item"
            onClick={() => this.createNewItem()}
          >
            <Icon type="plus" /> <span>Přidat Novou Položku</span>
          </Card>
        );

        return (
          <div className="info-items">
            {allItems} {renderNewItems}
          </div>
        );
        // const allItems = cmsInfoItems.map(item => {
        //   let show = true;
        //   this.props.cmsInfoReducer.cmsInfoDeletedItems.forEach(id => {
        //     if (id == item.id) {
        //       show = false;
        //     }
        //   });

        //   if (!show) {
        //     return null;
        //   }

        //   return (
        //     <div className="info-row" key={"cms-item-" + item.id}>
        //       <input
        //         type="text"
        //         value={item.name}
        //         id="name"
        //         onChange={(e, idOfItem) =>
        //           this.updateValueInExistingItems(e, item.id)
        //         }
        //       />
        //       <input
        //         type="checkbox"
        //         id="isVisible"
        //         checked={item.isVisible}
        //         onChange={(e, idOfItem) =>
        //           this.updateValueInExistingItems(e, item.id)
        //         }
        //       />
        //       <textarea
        //         defaultValue={item.description}
        //         id="description"
        //         onChange={(e, idOfItem) =>
        //           this.updateValueInExistingItems(e, item.id)
        //         }
        //       />
        //       <input
        //         type="number"
        //         value={item.order}
        //         id="order"
        //         onChange={(e, idOfItem) =>
        //           this.updateValueInExistingItems(e, item.id)
        //         }
        //       />
        //       <div>{item.icon}</div>
        //       <button onClick={id => this.props.cmsAddDeletedItem(item.id)}>
        //         Delete
        //       </button>
        //     </div>
        //   );
        // });
        // return (
        //   <div className="info-rows">
        //     {allItems}
        //     <hr />
        //   </div>
        // );
      } else {
        return <div> Loading... </div>;
      }
    };

    const renderNewItems = () => {
      let renderItems = null;
      if (cmsInfoItemsNew.length > 0) {
        renderItems = cmsInfoItemsNew.map((item, index) => {
          return (
            <div
              style={{ border: "1px solid blue", marginBottom: "1rem" }}
              key={"cms-item-new" + index}
            >
              <input
                type="text"
                value={item.name}
                id="name"
                onChange={(e, position) =>
                  this.updateValueInNewAddedItems(e, index)
                }
              />
              <input
                type="checkbox"
                id="isVisible"
                checked={item.isVisible}
                onChange={(e, position) =>
                  this.updateValueInNewAddedItems(e, index)
                }
              />
              <textarea
                defaultValue={item.description}
                id="description"
                onChange={(e, position) =>
                  this.updateValueInNewAddedItems(e, index)
                }
              />
              <input
                type="number"
                value={item.order}
                id="order"
                onChange={(e, position) =>
                  this.updateValueInNewAddedItems(e, index)
                }
              />
              <div>{item.icon}</div>
              <button onClick={position => this.deleteNewItem(index)}>
                Remove item
              </button>
            </div>
          );
        });
      }
      return <div>{renderItems}</div>;
    };

    const {
      antdModalVisible,
      antdModalConfirmLoading,
      antdModalText
    } = this.state;

    const { Option } = Select;

    const dropdownForIcons = () => {
      const mapOfIcons = returnMapOfIcons();
      const optionsForDropDown = mapOfIcons.map(item => {
        return (
          <Option value={item} key={item}>
            <IconRenderer iconName={item} />
          </Option>
        );
      });

      const tryIcoDropdown = (
        <Select
          style={{ width: 70 }}
          placeholder="Select a person"
          // optionFilterProp="children"
          value={this.state.newItem.icon}
          id="icon"
          onChange={(e, id, idOfItem) =>
            this.updateDropdownItemNewItem(e, "icon", "dejakyId")
          }
        >
          {optionsForDropDown}
        </Select>
      );

      return tryIcoDropdown;
    };

    const { TextArea } = Input;
    const { Meta } = Card;

    const modalBody = (
      <Card
        className="border-less"
        // actions={[returnDeleteCancelButton(), returnEditSaveButton()]}
      >
        <Meta
          avatar={dropdownForIcons()}
          title={
            <Input
              type="text"
              placeholder="Zadejte nadpis"
              value={this.state.newItem.name}
              onChange={e => this.updateValueInNewItem(e, "name")}
            />
          }
          description={
            <TextArea
              id="description"
              placeholder="Zadejte obsah"
              onChange={e => this.updateValueInNewItem(e, "description")}
              value={this.state.newItem.description}
              autosize={{ minRows: 2, maxRows: 6 }}
            />
          }
        />
      </Card>
    );

    return (
      <div>
        <Modal isVisible={this.state.isModalActive}>
          {this.state.modalBody}
        </Modal>
        <AntdModal
          okText="Uložit"
          cancelText="Zrušit"
          title="Title"
          visible={antdModalVisible}
          onOk={() => this.antdModalOkFunction()}
          confirmLoading={antdModalConfirmLoading}
          onCancel={() => this.antdModalCloseHandler()}
        >
          {modalBody}
        </AntdModal>
        {renderCmsCongifSection()}
        <br />
        {renderCmsItemsSection()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cmsInfoReducer: state.cmsInfoReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cmsInfoGetGlobalConfig: () => dispatch(cmsInfoGetGlobalConfig()),
    cmsInfoUpdateGlobalConfig: (id, value) =>
      dispatch(cmsInfoUpdateGlobalConfig(id, value)),
    cmsInfoSaveGlobalConfig: () => dispatch(cmsInfoSaveGlobalConfig()),
    cmsInfoGetModuleItems: () => dispatch(cmsInfoGetModuleItems()),
    cmsInfoModulePushNewItem: () => dispatch(cmsInfoModulePushNewItem()),
    cmsInfoUpdateNewItem: (position, id, value) =>
      dispatch(cmsInfoUpdateNewItem(position, id, value)),
    cmsInfoUpdateExistingItem: (id, idOfItem, value) =>
      dispatch(cmsInfoUpdateExistingItem(id, idOfItem, value)),
    cmsInfoDeleteNewItem: position => dispatch(cmsInfoDeleteNewItem(position)),
    cmsSaveInfoContent: () => dispatch(cmsSaveInfoContent()),
    cmsAddDeletedItem: id => dispatch(cmsAddDeletedItem(id)),
    cmsInfoSaveSingleItem: item => dispatch(cmsInfoSaveSingleItem(item))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCmsPageInfo);
