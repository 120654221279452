const initState = {
  version: null,
  versionContent: [],
  error: false,
  isFetching: true
};

const versionReducer = (state = initState, action) => {
  switch (action.type) {
    case "VERSION_INFO_START":
      return {
        ...state,
        version: null,
        versionContent: [],
        error: false,
        isFetching: true
      };
    case "VERSION_INFO_SUCCESS":
      return {
        ...state,
        version: action.version,
        versionContent: action.versionContent,
        error: false,
        isFetching: false
      };
    case "VERSION_INFO_FAIL":
      return {
        ...state,
        version: null,
        versionContent: [],
        error: true,
        isFetching: false
      };
    default:
      return state;
  }
};

export default versionReducer;
