import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ScreenMenu from '../Menu/ScreenMenu'
import Footer from '../Footer/Footer'

import HomepageNearby from './HomepageSections/HomepageNearby'
import HomepageContacts from './HomepageSections/HomepageContacts'
import HomepageAboutUs from './HomepageSections/HomepageAboutUs'
import HomepagePricing from './HomepageSections/HomepagePricing'
import HomepageGallery from './HomepageSections/HomepageGallery'
import HomepageSection from '../Homepage/HomepageSection'

import { fetchHomePageModules } from '../../store/actions/homepageActions'

import firebase from 'firebase/app'

import 'firebase/functions'

// import { getFirestore } from "redux-firestore"; //DELETEME
import axios from 'axios'

const showSections = () => {
  const horizont = 750
  const voucherSection = document.querySelector('#voucher')
  const aboutUsSection = document.querySelector('#o-nas')
  // const aboutUsSectionPos = aboutUsSection.getBoundingClientRect();
  const pricingSection = document.querySelector('#cenik')
  // const pricingSectionPos = pricingSection.getBoundingClientRect();
  const nearbySection = document.querySelector('#okoli')
  // const nearbySectionPos = nearbySection.getBoundingClientRect();
  const contactSection = document.querySelector('#kontakt')
  // const contactSectionPos = contactSection.getBoundingClientRect();
  const gallerySection = document.querySelector('#galerie')
  // const gallerySectionPos = gallerySection.getBoundingClientRect();
  const menu = document.querySelector('.screen-menu')
  const menuRect = menu.getBoundingClientRect()

  const yOffset = window.pageYOffset //320

  if (voucherSection && yOffset >= voucherSection.offsetTop - horizont) {
    voucherSection.classList.add('visible')
  }
  if (aboutUsSection && yOffset >= aboutUsSection.offsetTop - horizont) {
    aboutUsSection.classList.add('visible')
  }
  if (pricingSection && yOffset >= pricingSection.offsetTop - horizont) {
    pricingSection.classList.add('visible')
  }
  if (nearbySection && yOffset >= nearbySection.offsetTop - horizont) {
    nearbySection.classList.add('visible')
  }
  if (contactSection && yOffset >= contactSection.offsetTop - horizont) {
    contactSection.classList.add('visible')
  }
  if (gallerySection && yOffset >= gallerySection.offsetTop - horizont) {
    gallerySection.classList.add('visible')
  }
  if (yOffset >= menuRect.height && yOffset >= Math.round(window.innerHeight)) {
    menu.classList.add('mini')
    document.body.classList.add('menu-mini-active')
  } else if (yOffset <= Math.round(window.innerHeight)) {
    menu.classList.remove('mini')
    document.body.classList.remove('menu-mini-active')
  }
}

class Homepage extends Component {
  async componentDidMount() {
    this.props.fetchHomePageModules()
    document.addEventListener('scroll', showSections)
    // const dataFromC = await fetch("http://localhost:51181/api/values/");
    // const body = await dataFromC.json();
    // console.log("body", body);
    //   .then(res => console.log("resFromC", res.json()))
    //   .then(data => console.log("data", data))
    //   .catch(err => console.log("errFromC", err)); // const reader = body.getReader();
    // // console.log(dataFromC);

    //DELETE ME
    // const firestore = getFirestore();
    // firestore
    //   .collection("reservation")
    //   .doc("000")
    //   .get()
    //   .then(snapshot => {
    //     const data = snapshot.data();
    //     console.log("res", data);
    //   })
    //   .catch(err => console.log("err", err));
    // axios
    //   .get("http://localhost:5000/jizersky-pension/us-central1/getData")
    //   .then(res => console.log("resX", res))
    //   .catch(err => console.log("err", err));
    // axios
    //   .get("https://us-central1-jizersky-pension.cloudfunctions.net/getData")
    //   .then(res => console.log("resX", res))
    //   .catch(err => console.log("err", err));

    const msg = firebase.functions().httpsCallable('getData')
    msg()
      .then(res => console.log('resX', res))
      .catch(err => console.log('err', err))
  }

  componentWillUnmount() {
    document.body.classList.remove('menu-mini-active')
    document.removeEventListener('scroll', showSections)
  }

  render() {
    // console.log("Mega přehled :)", this.props.homepageReducer);

    let modulesRender = null

    if (!this.props.homepageReducer.modulesLoading) {
      modulesRender = (
        <div>
          {/* <div id="voucher" className="section-hp voucher">
            <HomepageSection title={'Podpořte nás'}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}>
                <p>
                  Koupí voucheru si můžete předplatit ubyotování a můžete tak
                  podpořit a hlavně udržet náš penión.
                </p>
                <p>
                  Platnost neomezená. Datum příjezdu na pobyt po telefonickém
                  potvrzení data ubytování.
                </p>
                <Link
                  className="btn-voucher"
                  style={{
                    background: '#DD8640',
                    padding: '1rem 2rem',
                    color: '#fff',
                    display: 'inline-block',
                    marginTop: '1rem',
                    alignSelf: 'center',
                  }}
                  to="/voucher">
                  Koupit voucher
                </Link>
              </div>
            </HomepageSection>
          </div> */}

          <div id="o-nas" className="section-hp">
            <HomepageAboutUs
              moduleAboutUsGlobal={
                this.props.homepageReducer.homepageModulesData.filter(
                  item => item.type === 1
                )[0]
              }
            />
          </div>

          <div id="cenik" className="section-hp">
            <HomepagePricing
              modulePricing={this.props.homepageReducer.pricesItemsData}
              modulePricingGlobal={
                this.props.homepageReducer.homepageModulesData.filter(
                  item => item.type === 2
                )[0]
              }
            />
          </div>

          <div id="okoli" className="section-hp">
            <HomepageNearby
              moduleNearby={this.props.homepageReducer.nearbyItemsData}
              moduleNearbyGlobal={
                this.props.homepageReducer.homepageModulesData.filter(
                  item => item.type === 3
                )[0]
              }
            />
          </div>

          <div id="galerie" className="section-hp">
            <HomepageGallery
              moduleGalleryContent={this.props.homepageReducer.galleryContent}
              moduleGalleryGlobal={
                this.props.homepageReducer.homepageModulesData.filter(
                  item => item.type === 4
                )[0]
              }
            />
          </div>

          {/*<HomepageNearby
            moduleGallery={this.props.homepageReducer.nearbyItemsData}
            moduleGalleryGlobal={
              this.props.homepageReducer.homepageModulesData.filter(
                item => item.type === 4
              )[0]
            }
          />*/}

          <div id="kontakt" className="section-hp">
            <HomepageContacts
              moduleContacts={this.props.homepageReducer.contactsItemsData}
              moduleContactsGlobal={
                this.props.homepageReducer.homepageModulesData.filter(
                  item => item.type === 5
                )[0]
              }
            />
          </div>
        </div>
      )
    }

    let adminView = null

    const adminIcon = (
      <svg
        id="Layer_1"
        height="512"
        viewBox="0 0 512 512"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1">
        <path d="m362.441 69.35-21.293-21.515-19.506 16.165a6 6 0 0 1 -7.073.416 108.193 108.193 0 0 0 -35.875-15.127 5.777 5.777 0 0 1 -4.722-5.09l-2.457-25.199h-30.672l-2.457 25.2a5.972 5.972 0 0 1 -4.711 5.263 104.33 104.33 0 0 0 -35.82 15 6 6 0 0 1 -7.139-.417l-19.532-16.21-21.614 21.551 16.177 19.519a5.991 5.991 0 0 1 .388 7.1 108.849 108.849 0 0 0 -15.195 35.836 6.1 6.1 0 0 1 -5.363 4.671l-25.577 2.457v30.611l25.577 2.457a6.2 6.2 0 0 1 5.453 4.695 104.447 104.447 0 0 0 15.116 35.767 6 6 0 0 1 -.378 7.151l-16.187 19.52 21.6 21.554 19.532-16.205a6 6 0 0 1 7.073-.451 108.045 108.045 0 0 0 35.876 15.058 6.188 6.188 0 0 1 4.721 5.452l2.46 25.421h30.672l2.457-25.425a5.943 5.943 0 0 1 4.712-5.237 104.585 104.585 0 0 0 35.82-15.124 6 6 0 0 1 7.141.355l19.534 16.179 21.609-21.568-16.177-19.527a6 6 0 0 1 -.388-7.106 108.836 108.836 0 0 0 15.195-35.837 6.1 6.1 0 0 1 5.356-4.67l25.226-2.453v-30.617l-25.577-2.457a6.2 6.2 0 0 1 -5.453-4.7 104.46 104.46 0 0 0 -15.116-35.764 6 6 0 0 1 .378-7.152zm-17.1 84.926a89.309 89.309 0 1 1 -89.311-89.186 89.348 89.348 0 0 1 89.309 89.186z" />
        <path d="m256.03 77.09a77.186 77.186 0 1 0 77.309 77.186 77.334 77.334 0 0 0 -77.309-77.186zm-18.393 99.994a6 6 0 1 1 -7.238 9.571l-36.787-27.818a6 6 0 0 1 0-9.571l36.788-27.818a6 6 0 1 1 7.238 9.571l-30.459 23.033zm30.571-63.4-12.559 82.976a6 6 0 1 1 -11.865-1.8l12.558-82.972a6 6 0 1 1 11.866 1.8zm52.621 40.364a6 6 0 0 1 -2.381 4.785l-36.788 27.822a6 6 0 0 1 -7.237-9.571l30.458-23.032-30.459-23.033a6 6 0 1 1 7.239-9.571l36.787 27.818a6 6 0 0 1 2.381 4.786z" />
        <path d="m46 355h420v60h-420z" />
        <path d="m358.3 93.569a114.815 114.815 0 0 1 13.461 31.537l26.646 2.507a6.09 6.09 0 0 1 5.59 5.909v41.507a6.149 6.149 0 0 1 -5.58 5.971l-26.4 2.566a120.515 120.515 0 0 1 -13.417 31.761l16.912 20.4a6 6 0 0 1 -.381 8.074l-29.34 29.276a6 6 0 0 1 -8.068.361l-20.376-16.917a116.14 116.14 0 0 1 -31.847 13.329l-2.566 26.65a6.066 6.066 0 0 1 -5.972 5.5h-41.562a6.068 6.068 0 0 1 -5.973-5.5l-2.567-26.441a120.268 120.268 0 0 1 -31.8-13.471l-20.414 16.894a6.006 6.006 0 0 1 -8.071-.387l-29.354-29.285a6.007 6.007 0 0 1 -.391-8.081l16.87-20.371a116.331 116.331 0 0 1 -13.461-31.787l-26.649-2.571a6.148 6.148 0 0 1 -5.59-5.972v-41.506a6.12 6.12 0 0 1 5.59-5.941l26.749-2.539a118.569 118.569 0 0 1 13.424-31.442l-9.1-10.6h-98.663v260h420v-260h-98.666z" />
        <path d="m364.16 482h-40.2l-15.352-55h-105.219l-15.352 55h-40.2a6 6 0 0 0 0 12h216.323a6 6 0 1 0 0-12z" />
      </svg>
    )

    if (this.props.adminView) {
      adminView = (
        <div className="admin-view-shower">
          Stránka je v offline režimu, stránku můžete vidět pouze jako
          administrátor
          <Link to="/admin" className="admin-button">
            {adminIcon} Přejít do administrace
          </Link>
        </div>
      )
    }

    return (
      <div>
        {adminView}
        <ScreenMenu />
        <div className="container">{modulesRender}</div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    homepageReducer: state.homepageReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchHomePageModules: () => dispatch(fetchHomePageModules()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage)
